import Vue from "vue";
import { localizationService } from "@/main";
export default Vue.extend({
    data() {
        return {
            localizationService,
        };
    },
    props: {
        logo: {
            type: String,
            default: "",
        },
        passwordRecoveryFlow: {
            type: Boolean,
            default: false,
        },
        showTitle: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        pageTitle() {
            let title = {
                text: "",
                append: "",
            };
            if (this.passwordRecoveryFlow) {
                title.text = this.localizationService.FormLoc("recupero_password_titolo_testo");
                title.append = this.localizationService.FormLoc("recupero_password_titolo_testo_append");
            }
            else {
                title.text = this.localizationService.FormLoc("effettua_login");
                title.append = "Smartform";
            }
            return title;
        },
        pageSubtitle() {
            let subtitle = {
                text: "",
            };
            if (this.passwordRecoveryFlow) {
                subtitle.text = this.localizationService.FormLoc("recupero_password_descrizione");
            }
            else {
                subtitle.text = this.localizationService.FormLoc("info_platform");
            }
            return subtitle;
        },
    },
});
