import Vue from "vue";
import CommonButton from "@/components/commons/CommonButton.vue";
import CommonIcon from "@/components/commons/CommonIcon.vue";
export default Vue.extend({
    components: {
        CommonButton,
        CommonIcon,
    },
    data() {
        return {
            visible: true,
        };
    },
    props: {
        title: {
            type: String,
            default: "",
        },
        cardBodyId: {
            type: String,
            default: '',
        },
        subtitle: {
            type: String,
            default: "",
        },
        icon: {
            type: Object,
            default: () => {
                return {};
            },
        },
        headerClass: {
            type: String,
            default: "",
        },
        titleClass: {
            type: String,
            default: "",
        },
        bodyClass: {
            type: String,
            default: "",
        },
        footerClass: {
            type: String,
            default: "",
        },
        noHeaderPadding: {
            type: Boolean,
            default: false,
        },
        noBodyOverflow: {
            type: Boolean,
            default: false,
        },
        noBodyPadding: {
            type: Boolean,
            default: false,
        },
        noFooterPadding: {
            type: Boolean,
            default: false,
        },
        flat: {
            type: Boolean,
            default: false,
        },
        tile: {
            type: Boolean,
            default: false,
        },
        isCollapsible: {
            type: Boolean,
            default: false,
        },
        showHeader: {
            type: Boolean,
            default: true,
        },
        showBody: {
            type: Boolean,
            default: true,
        },
        showFooter: {
            type: Boolean,
            default: true,
        },
        isCollapseVisibile: {
            type: Boolean,
            default: undefined,
        },
    },
    methods: {
        toggleBodyCollapse() {
            this.visible = !this.visible;
        },
    },
    watch: {
        isCollapseVisibile(newValue) {
            const vm = this;
            if (newValue) {
                vm.visible = vm.isCollapseVisibile;
            }
        },
    },
    mounted() {
        if (this.isCollapseVisibile !== undefined) {
            this.visible = this.isCollapseVisibile;
        }
    },
});
