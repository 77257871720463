import Vue from "vue";
export default Vue.extend({
    props: {
        field: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
});
