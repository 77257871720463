import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import CommonIcon from "@/components/commons/CommonIcon.vue";
import CommonButton from "@/components/commons/CommonButton.vue";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
library.add(faChevronRight);
export default Vue.extend({
    name: 'CommonMapTree',
    components: { CommonIcon, CommonButton },
    props: {
        mapSite: {
            type: Array,
            default: () => [],
        },
        customerCode: String
    },
    data() {
        return {
            rootTag: { icon: 'fas fa-tag', color: '#000000' },
            leafTag: { icon: 'fas fa-tags', color: '#000000' },
            isOpen: false,
            openCollapse: []
        };
    },
    watch: {
        mapSite: {
            handler(newValue, oldValue) {
                if (!this.openCollapse.length) {
                    newValue.forEach((elem) => {
                        this.openCollapse.push(elem.id);
                    });
                }
            },
            deep: true
        }
    },
    methods: {
        openCollapseFn(id) {
            if (this.openCollapse.includes(id)) {
                this.openCollapse = this.openCollapse.filter((cId) => cId !== id);
            }
            else {
                this.openCollapse.push(id);
            }
        },
        goToPath(elem) {
            this.$router.push(elem.level ? ('/' + this.customerCode + '/open/' + elem.code_tag) : ('/' + this.customerCode));
        }
    }
});
