import { BackgroundTypes, ModalManagementMode } from "@/enums";
import TemplateMixins from "@/mixins/templatesMixin";
import Vue from "vue";
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";
import { highlight, languages } from "prismjs/components/prism-core.min.js";
import "prismjs/components/prism-css";
import { localizationService } from "@/main";
import AngleInput from "vue-angle-input";
import logoOptionsJson from '../../../public/json/logos.json';
import faviconOptionsJson from '../../../public/json/favicons.json';
import backgroundOptionsJson from '../../../public/json/backgrounds.json';
import customLocStringsJson from '../../../public/json/customlocstrings.json';
import chat from "@/store/chat";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
library.add(faTrash);
export default Vue.extend({
    components: {
        PrismEditor,
        AngleInput
    },
    mixins: [TemplateMixins],
    props: {
        showModal: { type: Boolean },
        mode: {
            type: String,
        },
        tagFormsList: Array,
    },
    data() {
        return {
            templateList: this.getTemplatesList(),
            defaultTemplateParams: this.getDefaultTemplateParamsList(),
            ModalManagementMode,
            internalShowModal: false,
            formSetting: {},
            id: 0,
            code_form: "",
            description: "",
            code_tag: "",
            client_url: "",
            active: false,
            params: {},
            formParams: {},
            logoParams: [],
            templateParams: {},
            localizationService,
            okButton: "",
            okButtonColor: "primary",
            addLogoParamButton: true,
            logoOptions: [],
            faviconOptions: [],
            backgroundOptions: [],
            customLocOptions: [],
            liveChat: {},
            surveyNoRecompile: false
        };
    },
    mounted() {
        this.okButton = this.localizationService.DashLoc('salva');
        this.templateList = this.getTemplatesList();
        this.defaultTemplateParams = this.getDefaultTemplateParamsList();
        /*this.logoOptions = [
          {value: "undefined", text: '---Seleziona un logo---'}
        ];*/
        logoOptionsJson.forEach((logo) => {
            const param = { value: logo, text: logo };
            this.logoOptions.push(param);
        });
        /*this.faviconOptions = [
          {value: '', text: '---Seleziona una favicon---'}
        ];*/
        faviconOptionsJson.forEach((favicon) => {
            const param = { value: favicon, text: favicon };
            this.faviconOptions.push(param);
        });
        /*this.backgroundOptions = [
          {value: '', text: '---Seleziona un background---'}
        ];*/
        backgroundOptionsJson.forEach((background) => {
            const param = { value: background, text: background };
            this.backgroundOptions.push(param);
        });
        customLocStringsJson.forEach((customlocstring) => {
            const param = { value: customlocstring, text: customlocstring };
            this.customLocOptions.push(param);
        });
        this.params = {
            sso_secret_aes: { type: "text" },
            captcha: { type: "bool", pilots: ["always_captcha"] },
            always_captcha: { type: "bool", hidden: true },
            survey_no_recompile: { type: "bool", hidden: this.surveyNoRecompile },
            logo: { type: "select", options: this.logoOptions },
            favicon: { type: "select", options: this.faviconOptions },
            custom_loc_strings: { type: "select", options: this.customLocOptions },
            background_type: {
                "type": "select", options: [
                    //{value: '', text: '---Seleziona un tipo di sfondo---'},
                    { value: BackgroundTypes.IMG, text: 'Immagine', pilots: ["background_img"] },
                    { value: BackgroundTypes.COLOR, text: 'Colore', pilots: ["background_color"] },
                    { value: BackgroundTypes.GRADIENT, text: 'Gradiente', pilots: ["background_gradient"] }
                ]
            },
            background_img: { "type": "select", hidden: true, options: this.backgroundOptions, pilotedBy: ["background_type"] },
            background_color: { "type": "color", hidden: true, pilotedBy: ["background_type"] },
            background_gradient: { "type": "gradient", hidden: true, pilotedBy: ["background_type"] }
        };
    },
    computed: {
        chat() {
            return chat;
        },
        validationGlobalState() {
            if (this.code_form != "" &&
                this.code_tag != "" &&
                this.client_url != "" &&
                this.description != "" &&
                this.templateParams.name &&
                this.templateParams.name != "" &&
                this.validateUrl()) {
                return false;
            }
            else {
                return true;
            }
        },
        isCodeDisabled() {
            switch (this.mode) {
                case ModalManagementMode.DELETE: {
                    return true;
                }
                case ModalManagementMode.ADD: {
                    return false;
                }
                default: {
                    return false;
                }
            }
        },
        partialTitle() {
            switch (this.mode) {
                case ModalManagementMode.ADD:
                    return this.localizationService.DashLoc('aggiungi').toString();
                case ModalManagementMode.EDIT:
                    return this.localizationService.DashLoc('modifica').toString();
                case ModalManagementMode.DELETE:
                    return this.localizationService.DashLoc('elimina').toString();
                case ModalManagementMode.DUPLICATE:
                    return this.localizationService.DashLoc('duplica').toString();
            }
        },
    },
    methods: {
        handleAngleChange(params, p, angle) {
            if (params[p]) {
                params[p].angle = angle;
            }
        },
        booleanPilots(param) {
            let value = this.formParams[param];
            if (value) {
                this.lodash.forEach(this.params[param].pilots, (p) => this.params[p].hidden = false);
            }
            else {
                this.lodash.forEach(this.params[param].pilots, (p) => {
                    this.params[p].hidden = true;
                    this.formParams[p] = false;
                });
            }
        },
        selectPilotsGeneric(model, params, parameter) {
            const theField = params[parameter];
            if (!!theField) {
                const pilotedParams = this.lodash.filter(params, (par) => par.pilotedBy && par.pilotedBy.includes(parameter));
                this.lodash.map(pilotedParams, (par) => par.hidden = true);
                const a = model[parameter];
                if (a != null && +a != null && !isNaN(+a) && theField.options) {
                    let selected = theField.options[a];
                    if (selected && selected.pilots) {
                        this.lodash.map(selected.pilots, (p) => params[p].hidden = false);
                    }
                }
            }
        },
        highlighter(code) {
            return highlight(code, languages.css); // languages.<insert language> to return html with markup
        },
        openModal(res, tagFormsList) {
            this.code_form = res.code_form;
            this.description = res.description;
            this.code_tag = res.code_tag;
            this.client_url = res.client_url;
            this.active = !res.active;
            this.id = res.id;
            this.surveyNoRecompile = !res.survey_no_recompile;
            this.params.survey_no_recompile.hidden = this.surveyNoRecompile;
            this.internalShowModal = true;
            this.formSetting = res;
            if (res.params != null) {
                this.formParams = res.params;
            }
            if (res.logoParams != null) {
                this.logoParams = res.logoParams;
            }
            if (res.templateParams != null) {
                this.templateParams = res.templateParams;
                if (this.templateParams.params === null) {
                    this.templateParams.params = {};
                }
                if (this.templateList[this.templateParams.name].params['showSearchbar']) {
                    if (this.templateParams.params['showSearchbar'] == null) {
                        this.templateParams.params['showSearchbar'] = this.templateList[this.templateParams.name].params['showSearchbar'].defaultValue;
                    }
                }
            }
            if (res.liveChatParams != null) {
                this.liveChat = res.liveChatParams;
            }
            if (this.mode === ModalManagementMode.ADD) {
                this.freeComponents();
                this.addLogoParamButton = true;
                this.okButton = this.localizationService.DashLoc('salva');
                this.okButtonColor = "primary";
            }
            else if (this.mode === ModalManagementMode.DELETE) {
                this.addLogoParamButton = false;
                this.okButton = this.localizationService.DashLoc('elimina');
                this.okButtonColor = "danger";
            }
            else if (this.mode === ModalManagementMode.EDIT) {
                this.addLogoParamButton = true;
                this.code_tag = res.code_tag;
                this.okButton = this.localizationService.DashLoc('salva');
                this.okButtonColor = "primary";
            }
            else {
                this.addLogoParamButton = true;
                this.okButton = this.localizationService.DashLoc('salva');
                this.okButtonColor = "primary";
            }
            this.lodash.forEach(Object.keys(this.params), (p) => {
                this.booleanPilots(p);
                this.selectPilotsGeneric(this.formParams, this.params, p);
            });
            // selectPilotsGeneric(templateParams.params, templateList[templateParams.name].params, index)
            this.lodash.forEach(this.templateList, (template, name) => {
                if (name === this.templateParams.name) {
                    this.lodash.forEach(template.params, (_param, index) => {
                        this.selectPilotsGeneric(this.templateParams.params, this.templateList[this.templateParams.name].params, index);
                    });
                }
            });
            this.initializeGradientsParams(this.params, this.formParams);
        },
        initializeGradientsParams(params, model) {
            const gradientObject = Object.keys(this.lodash.pickBy(params, (p) => p.type === 'gradient'));
            this.lodash.forEach(gradientObject, (gradientProperty) => model[gradientProperty] = model[gradientProperty] || {
                from: '#000000',
                to: '#000000',
                angle: 0
            });
        },
        getFileNameValue(index) {
            if (this.templateParams.params[index]) {
                return this.templateParams.params[index].name;
            }
            else {
                return "";
            }
        },
        handleOk() {
            this.templateParams.params = this.lodash.pick(this.templateParams.params, Object.keys(this.lodash.merge(this.templateList[this.templateParams.name].params, this.defaultTemplateParams)));
            this.formSetting = {
                id: this.id,
                code_form: this.code_form,
                description: this.description,
                code_tag: this.code_tag,
                client_url: this.client_url,
                active: !this.active,
                params: this.formParams,
                logoParams: this.logoParams,
                templateParams: this.templateParams,
                liveChatParams: this.liveChat
            };
            this.$emit("formSettingByModal", this.formSetting, this.mode);
            this.freeComponents();
        },
        triggerFileInput(id) {
            const fileInput = document.getElementById(id);
            if (fileInput) {
                fileInput.click();
            }
        },
        uploadFileForm(file, model, params, parameter) {
            /*if (file) {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => {
                this.logoOptions.push({
                  text: file.name,
                  value: reader.result,
                })
                this.selectPilotsGeneric(model, params, parameter)
                Vue.set(this.formParams, parameter, this.logoOptions[this.logoOptions.length - 1].value);
              };
              reader.onerror = (error) => {
                console.error('Error: ', error);
              };
            }*/
        },
        deleteHallwaysImg(index) {
            Vue.set(this.templateParams.params, index, {
                img: '',
                name: ''
            });
        },
        deleteChatIconImg() {
            Vue.set(this.liveChat, 'chatIcon', {
                value: '',
                name: ''
            });
        },
        changeFile(event, index) {
            const file = event.target.files?.[0];
            if (file) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    Vue.set(this.templateParams.params, index, {
                        img: reader.result,
                        name: file.name
                    });
                    const fileInput = document.getElementById('fileInputImg');
                    if (fileInput) {
                        fileInput.value = '';
                    }
                };
                reader.onerror = (error) => {
                    console.error('Error: ', error);
                };
            }
        },
        changeChatIcon(event) {
            const file = event.target.files?.[0];
            if (file) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    Vue.set(this.liveChat, 'chatIcon', {
                        value: reader.result,
                        name: file.name
                    });
                    const fileInput = document.getElementById('fileInputChatIcon');
                    if (fileInput) {
                        fileInput.value = '';
                    }
                };
                reader.onerror = (error) => {
                    console.error('Error: ', error);
                };
            }
        },
        addNewLogoParam() {
            var param = { value: "", dimension: "", alt: "", offset: "" };
            this.logoParams.push(param);
        },
        removeLogoParam(index) {
            this.logoParams.splice(index, 1);
        },
        freeComponents() {
            this.code_form = "";
            this.description = "";
            this.code_tag = "";
            this.client_url = "";
            this.active = false;
            this.formParams = {};
            this.logoParams = [];
            this.templateParams = {};
            this.templateParams.params = {};
            Object.keys(this.getDefaultTemplateParamsList()).forEach(k => this.templateParams.params[k] = "");
        },
        validateUrl: function () {
            const regex = RegExp("^(https?|http)://[-a-zA-Z0-9+&@#/%?=~_|!:,.;]*[-a-zA-Z0-9+&@#/%=~_|]");
            return this.client_url.match(regex);
        }
    },
    watch: {
        templateParams: {
            deep: true,
            handler(newParams, oldParams) {
                if (!newParams.name || newParams.name === oldParams.name) {
                    return;
                }
                this.initializeGradientsParams(this.templateList[newParams.name].params, newParams.params);
            }
        }
    }
});
