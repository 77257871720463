import Vue from "vue";
import { localizationService } from "@/main";
import moment from "moment";
import SelectLocale from "@/components/SelectLocale.vue";
export default Vue.extend({
    watch: {
        responseMapping: {
            handler() {
                this.responseMappingFiltered = this.lodash.cloneDeep(this.responseMapping);
                this.applyFilter();
            },
            deep: true
        },
    },
    computed: {},
    props: {
        responseMapping: {
            type: Array,
        },
        showMoreMappingLog: {
            type: Boolean
        }
    },
    components: {
        SelectLocale
    },
    data() {
        return {
            logFilterModel: {},
            debounceTimeout: {},
            responseMappingFiltered: [],
            localizationService,
            actionOptions: ["Cancellato", "Non trovato", "Modificato"]
        };
    },
    mounted() {
        this.$emit("setFormTitle", this.localizationService.DashLoc('Log Mapping'));
        this.logFilterModel = {
            code_client: "",
            sub_client: "",
            sub_client2: "",
            sub_client3: "",
            timestamp: "",
            action: "",
            action_details: "",
        };
        this.responseMappingFiltered = this.lodash.cloneDeep(this.responseMapping);
    },
    methods: {
        theFilter(item) {
            let toReturn = true;
            let vueinstance = this;
            this.lodash.forEach(this.logFilterModel, function (f, k) {
                item[k] = item[k]?.toString() || "";
                toReturn = toReturn && (f.trim() == "" || item[k].toLowerCase().includes(vueinstance.logFilterModel[k].toLowerCase()));
            });
            return toReturn;
        },
        startDebounce() {
            if (this.debounceTimeout) {
                clearTimeout(this.debounceTimeout);
            }
            this.debounceTimeout = setTimeout(() => {
                this.applyFilter();
            }, 500); // delay
        },
        applyFilter() {
            this.responseMappingFiltered = this.lodash.filter(this.responseMapping, this.theFilter);
        },
        createDate(timestamp) {
            return moment(parseFloat(timestamp)).format('DD/MM/YYYY HH:mm');
        },
        getAction(action) {
            return this.actionOptions[action];
        },
        loadMoreMappingLog() {
            this.$emit("loadMoreMappingLog");
        },
        loadAllMappingLog() {
            this.$emit("loadAllMappingLog");
        }
    },
});
