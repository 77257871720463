import Vue from "vue";
import { faChevronRight, faEllipsis, faHome } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import CommonButton from "@/components/commons/CommonButton.vue";
import { generalFunctions, localizationService } from "@/main";
library.add(faHome);
library.add(faChevronRight);
library.add(faEllipsis);
export default Vue.extend({
    components: { CommonButton },
    data() {
        return {
            localizationService,
            generalFunctions,
            collapseBreadcrumbs: false
        };
    },
    props: {
        customerCode: String,
        templateName: String,
        breadcrumbs: {
            type: Array,
            default: []
        },
        tagsPath: {
            type: Array,
            default: []
        }
    },
    mounted() {
        const breadcrumbNav = this.$refs["breadcrumb-nav"];
        const breadcrumbContainer = this.$refs["breadcrumb-container"];
        if (breadcrumbNav && breadcrumbContainer) {
            generalFunctions.resizeElem(breadcrumbNav, (lunghezza) => {
                this.collapseBreadcrumbs = lunghezza >= breadcrumbContainer.offsetWidth && this.breadcrumbs.length > 2;
            });
        }
    },
    methods: {
        goToHome() {
            window.location.href = window.location.href.split(this.customerCode)[0] + this.customerCode;
        }
    },
    computed: {
        sliceBreadcrumb() {
            return this.breadcrumbs.slice(1, this.breadcrumbs.length - 1);
        }
    },
});
