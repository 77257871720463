import Vue from "vue";
import router from "@/router/index";
import Home from "@/views/templates/default2022/Home.vue";
import Login from "@/views/templates/default2022/Login.vue";
import Default2022Login from "@/views/templates/default2022/Login.vue";
import Validation from "@/views/templates/default2022/Validation.vue";
import Error from "@/views/templates/default2022/Error.vue";
import NotSupported from "@/views/templates/default2022/NotSupported.vue";
import AuthError from "@/views/templates/default2022/AuthError.vue";
import Mantainance from "@/views/templates/default2022/Mantainance.vue";
import { mapGetters } from "vuex";
import SurveyCompiled from "@/views/templates/default2022/SurveyCompiled.vue";
import SiteMap from "@/views/templates/default2022/SiteMap.vue";
export default Vue.extend({
    computed: {
        ...mapGetters('auth', {
            nextRoute: "getNextRoute"
        })
    },
    props: {
        templateName: String,
        customerCode: String,
        templateConfig: {}
    },
    data() {
        return {};
    },
    mounted() {
        router.addRoute({ path: '/:customerCode', name: 'HomeTemplate', component: Home, props: { templateName: this.templateName } });
        router.addRoute({ path: '/:customerCode/open/:codeTag', name: 'Autopilot', component: Home, props: { templateName: this.templateName } });
        router.addRoute({ path: '/:customerCode/mantainance', name: 'Mantainance', component: Mantainance, props: true });
        router.addRoute({ path: '/:customerCode/authError', name: 'AuthError', component: AuthError, props: true });
        router.addRoute({ path: '/:customerCode/notSupported', name: 'NotSupported', component: NotSupported, props: true });
        router.addRoute({ path: '/:customerCode/error', name: 'Error', component: Error, props: true });
        router.addRoute({ path: '/:customerCode/validation', name: 'Validation', component: Validation, props: true });
        router.addRoute({ path: '/:customerCode/login', name: 'Login', component: Login, props: true });
        router.addRoute({ path: '/:customerCode/:codeTag/token-login', name: 'TokenLogin', component: Default2022Login, props: { useTokenLogin: true } });
        router.addRoute({ path: '/:customerCode/surveyCompiled', name: 'SurveyCompiled', component: SurveyCompiled, props: true });
        router.addRoute({ path: '/:customerCode/siteMap', name: 'SiteMap', component: SiteMap, props: { templateName: this.templateName } });
        let rotta = { name: 'HomeTemplate' };
        if (this.nextRoute) {
            rotta = { path: this.nextRoute };
            this.$store.dispatch("auth/setNextRoute", undefined);
        }
        router.push(rotta);
    },
    methods: {},
    components: {
        Home,
        Login,
        Validation,
        Error,
        NotSupported,
        AuthError,
        Mantainance
    },
});
