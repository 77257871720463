import Vue from "vue";
import CommonButton from "./CommonButton.vue";
import { localizationService } from "@/main";
export default Vue.extend({
    components: {
        CommonButton,
    },
    data() {
        return {
            localizationService,
        };
    },
    props: {
        id: {
            type: String,
            default: "",
        },
        showModal: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: undefined,
        },
        size: {
            type: String,
            default: "lg",
        },
        noCloseOnBackdrop: {
            type: Boolean,
            default: true,
        },
        noCloseOnEsc: {
            type: Boolean,
            default: false,
        },
        hideHeaderClose: {
            type: Boolean,
            default: false,
        },
        hideFooter: {
            type: Boolean,
            default: false,
        },
        fullHeight: {
            type: Boolean,
            default: false,
        },
        bodyClass: {
            type: String,
            default: "",
        },
        footerBgVariant: {
            type: String,
            default: "",
        },
        footerBorderVariant: {
            type: String,
            default: "",
        },
        headerBgVariant: {
            type: String,
            default: "",
        },
        headerTextVariant: {
            type: String,
            default: "",
        },
        headerBorderVariant: {
            type: String,
            default: "",
        },
    },
    computed: {
        showModalComputed: {
            get() {
                return this.showModal;
            },
            set(value) {
                this.$emit("close", value);
            },
        },
    },
});
