var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"common-card",class:[
    { 'is-collapsible': _vm.isCollapsible },
    { 'show-collapse': _vm.visible },
    { 'show-header': _vm.showHeader },
    { 'show-body': _vm.showBody },
    { 'show-footer': _vm.showFooter },
    { flat: _vm.flat },
    { tile: _vm.tile } ],attrs:{"no-body":""}},[(_vm.showHeader)?_c('b-card-header',{staticClass:"common-card__header",class:[_vm.headerClass, { 'p-0': _vm.noHeaderPadding }],on:{"click":function($event){_vm.isCollapsible ? _vm.toggleBodyCollapse() : ''}}},[_vm._t("header",function(){return [_c('div',{staticClass:"common-card__header__info"},[(_vm.icon && Object.keys(_vm.icon).length > 0)?_c('common-icon',{attrs:{"icon":_vm.icon}}):_vm._e(),_c('div',{staticClass:"common-card__header__info__main"},[(_vm.title)?_c('h5',{staticClass:"common-card__title font-weight-bold mb-0",class:[_vm.titleClass],domProps:{"innerHTML":_vm._s(_vm.title)}}):_vm._e(),(_vm.subtitle)?_c('p',{staticClass:"common-card__subtitle",domProps:{"innerHTML":_vm._s(_vm.subtitle)}}):_vm._e()])],1),_vm._t("toggle",function(){return [(_vm.isCollapsible)?_c('common-button',{attrs:{"isCollapse":true,"showCollapse":_vm.visible,"variant":"action"}}):_vm._e()]})]})],2):_vm._e(),(_vm.showBody)?_c('b-card-body',{staticClass:"common-card__body",class:[
      _vm.bodyClass,
      { 'p-0': _vm.noBodyPadding },
      { 'overflow-unset': _vm.noBodyOverflow } ],attrs:{"id":_vm.cardBodyId}},[_c('b-collapse',{attrs:{"visible":""},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',{staticClass:"collapse__inner",class:{ 'p-3': _vm.isCollapsible }},[_vm._t("body")],2)])],1):_vm._e(),(_vm.showFooter)?_c('b-card-footer',{staticClass:"common-card__footer",class:[_vm.footerClass, { 'p-0': _vm.noFooterPadding }]},[_vm._t("footer")],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }