export default class ButtonMappingService {
    static _instance;
    constructor() {
    }
    static get Instance() {
        return this._instance || (this._instance = new this());
    }
    jsonObjectMap = {};
    jsonObject = {};
    buttonMapping(term) {
        return this.map(term, "errors");
    }
    map(term, module) {
        let key = term.toLowerCase().trim();
        if (Object.keys(this.jsonObjectMap).includes(module)) {
            this.jsonObject = this.jsonObjectMap[module];
        }
        else {
            this.jsonObject = require("@/assets/button-mapping/" + module + ".json");
            this.jsonObjectMap[module] = this.jsonObject;
        }
        if (module === 'errors') {
            if (key.indexOf('::') >= 0) {
                let obj = key.split('::').filter(word => word);
                key = obj[0];
            }
            return this.getMappedObj(key);
        }
        else {
            if (this.jsonObject.obj[key]) {
                return this.jsonObject.obj[key];
            }
            else {
                return key;
            }
        }
    }
    getMappedObj(term) {
        if (this.jsonObject.obj[term]) {
            return this.jsonObject.obj[term];
        }
        else {
            return this.jsonObject.obj["generic"];
        }
    }
}
