import { SET_AUTH_TOKEN, SET_NEXT_ROUTE, SET_OTP_CODE, SET_IS_OTP_CODE_TO_SEND } from '../mutation-types';
const actions = {
    setToken: ({ commit }, authToken) => {
        commit(SET_AUTH_TOKEN, authToken);
    },
    setOtpCode: ({ commit }, otpCode) => {
        commit(SET_OTP_CODE, otpCode);
    },
    setNextRoute: ({ commit }, nextRoute) => {
        commit(SET_NEXT_ROUTE, nextRoute);
    },
    setIsOtpToSend: ({ commit }) => {
        commit(SET_IS_OTP_CODE_TO_SEND, true);
    }
};
export default actions;
