import Vue from "vue";
export default Vue.extend({
    data() {
        return {
            htmlHeaderList: []
        };
    },
    props: {
        themeColor: {
            type: String
        }
    },
    computed: {},
    mounted() {
        const root = document.querySelector(':root');
        root?.style.setProperty('--themeBtnColor', this.themeColor);
    },
    methods: {
        updateHeaderFields(formFields, reset = false, lang) {
            if (reset) {
                this.htmlHeaderList.splice(0);
            }
            else if (!!lang) {
                this.htmlHeaderList = this.htmlHeaderList.filter((header) => !header.lang || lang === header.lang);
            }
            formFields.filter((header) => ['HTML_HEADER', 'HTML_HEADER_BUTTON'].includes(header.type))
                .forEach((header) => {
                const { type, value, title } = header;
                this.htmlHeaderList.push({
                    type,
                    value,
                    title,
                    lang
                });
            });
        }
    }
});
