import Vue from "vue";
import CommonButton from "./CommonButton.vue";
import { localizationService } from "@/main";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
library.add(faSearch);
export default Vue.extend({
    data() {
        return {
            localizationService,
        };
    },
    components: {
        CommonButton,
    },
    props: {
        query: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: true,
        },
        size: {
            type: String,
            default: "md",
        },
        templateName: String,
        legacy: {
            type: Boolean,
            default: true,
        },
        disableCta: {
            type: Boolean,
            default: false,
        },
        autocomplete: {
            type: String,
            default: undefined,
        },
        focusOnLoad: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        if (this.focusOnLoad) {
            this.$nextTick(() => {
                setTimeout(() => { this.setFocusOnInput(); }, 500);
            });
        }
    },
    methods: {
        setFocusOnInput() {
            const inputElement = this.$refs.searchInput;
            if (inputElement) {
                inputElement.focus();
            }
        }
    },
    computed: {
        queryComputed: {
            get() {
                return this.query;
            },
            set(newValue) {
                this.$emit("input", newValue);
            },
        },
    },
});
