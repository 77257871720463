import Vue from "vue";
import { localizationService } from "@/main";
export default Vue.extend({
    computed: {},
    data() {
        return {
            image: "",
            localizationService
        };
    },
    mounted() {
        document.body.style.backgroundImage = "url('" + require("@/assets/default-bg.png") + "')";
        document.body.style.backgroundAttachment = "fixed";
        document.body.style.backgroundRepeat = "no-repeat";
        document.body.style.backgroundSize = "cover";
    },
    methods: {}
});
