var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{staticClass:"form-password-recovery login__form password-recovery",on:{"submit":function($event){return _vm.handlePasswordChange($event)}}},[(_vm.passwordRecoveryFlow.stepIndex === 0)?_c('div',[_c('common-varchar',{staticClass:"mb-3",attrs:{"id":'input-name',"label":_vm.localizationService.FormLoc('tuo_nome_utente'),"model-value":_vm.passwordRecoveryFlow.username,"placeholder":_vm.localizationService.FormLoc('digita_nome_utente')},on:{"input":function($event){_vm.passwordRecoveryFlow.username = $event}}}),_c('common-button',{staticClass:"w-100 mb-3",attrs:{"text":_vm.localizationService.FormLoc('recupero_password_invia_mail'),"is-next":true,"is-prev":false,"animate-on-hover":true,"disabled":!_vm.passwordRecoveryFlow.username,"variant":"primary","buttonType":"submit"}}),_c('common-button',{staticClass:"w-100",attrs:{"text":_vm.localizationService.FormLoc('survey_step_previous'),"is-prev":true,"outline":"","variant":"primary"},on:{"clickEvent":function($event){return _vm.handleExit()}}})],1):_vm._e(),(_vm.passwordRecoveryFlow.stepIndex === 1)?_c('div',[_c('common-varchar',{staticClass:"mb-3",attrs:{"id":'password-new',"label":_vm.localizationService.FormLoc('recupero_password_nuova_password'),"placeholder":_vm.localizationService.FormLoc('recupero_password_nuova_password'),"model-value":_vm.passwordRecoveryFlow.password.new,"fieldType":"password","inputPassword":""},on:{"input":function($event){_vm.passwordRecoveryFlow.password.new = $event}}}),_c('common-varchar',{staticClass:"mb-3",attrs:{"id":'password-new-confirm',"label":_vm.localizationService.FormLoc(
          'recupero_password_conferma_nuova_password'
        ),"placeholder":_vm.localizationService.FormLoc(
          'recupero_password_conferma_nuova_password'
        ),"model-value":_vm.passwordRecoveryFlow.password.newConfirmed,"fieldType":"password","inputPassword":""},on:{"input":function($event){_vm.passwordRecoveryFlow.password.newConfirmed = $event}}}),_c('common-label',{attrs:{"text":_vm.localizationService.FormLoc(
          'recupero_password_requisito_password_lunghezza_1'
        ) +
        ' ' +
        _vm.passwordRecoveryFlow.password.requirements.minLength +
        ' ' +
        _vm.localizationService.FormLoc(
          'recupero_password_requisito_password_lunghezza_2'
        ),"variant":_vm.passwordMinLengthRequirement ? 'success' : 'danger'}}),_c('common-label',{attrs:{"text":_vm.localizationService.FormLoc(
          'recupero_password_requisito_password_lettere_maiuscole_minuscole'
        ),"variant":_vm.passwordContainsLowerCaseAndUpperCaseRequirement
          ? 'success'
          : 'danger'}}),_c('common-label',{attrs:{"text":_vm.localizationService.FormLoc(
          'recupero_password_requisito_password_numero'
        ),"variant":_vm.passwordContainsAtLeastOneNumberRequirement ? 'success' : 'danger'}}),_c('common-label',{attrs:{"text":_vm.localizationService.FormLoc(
          'recupero_password_requisito_password_carattere_speciale'
        ),"variant":_vm.passwordContainsSpecialCharacterRequirement ? 'success' : 'danger'}}),_c('common-label',{attrs:{"text":_vm.localizationService.FormLoc(
          'recupero_password_requisito_password_corrispondenti'
        ),"variant":_vm.passwordsAreTheSameRequirement ? 'success' : 'danger'}}),_c('common-button',{staticClass:"w-100 mt-4",attrs:{"text":_vm.localizationService.FormLoc('recupero_password_cambia_password'),"disabled":!_vm.passwordRecoveryFlow.password.new ||
        !_vm.passwordRecoveryFlow.password.newConfirmed ||
        !_vm.areNewPasswordRequirementsPassed,"variant":"primary","buttonType":"submit"}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }