import Vue from "vue";
import { SSO_KEY } from "@/constants";
export default Vue.extend({
    mounted() {
        this.$store.dispatch(SSO_KEY.SET_STORE_OTP_CODE, this.$route.query.code);
        let cookieName = `${SSO_KEY.COOKIE_OTP_CODE}_${this.$route.params.customerCode}`;
        this.$cookies.set(cookieName, btoa(this.$route.query.code), 60 * 30); // 30min durata
        if (sessionStorage.getItem('firstTime')) {
            sessionStorage.setItem('firstTime', "false");
            if (this.$route.query.redirect) {
                let path = "/" + this.$route.params.customerCode + "/open/" + this.$route.query.redirect;
                this.$store.dispatch("auth/setNextRoute", path);
            }
            else {
                this.$router.push("/" + this.$route.params.customerCode);
            }
        }
        else {
            this.$router.push({ name: 'HomeTemplate' });
        }
    },
});
