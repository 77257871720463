import Vue from "vue";
import loginMixin from "@/mixins/loginMixin";
import AppWelcome from "@/components/Welcome/AppWelcome.vue";
import CommonHeader from "@/components/commons/CommonHeader.vue";
import CommonButton from "@/components/commons/CommonButton.vue";
import CommonLabel from "@/components/commons/CommonLabel.vue";
import CommonVarchar from "@/components/commons/CommonVarchar.vue";
import FormLogin from "@/components/Forms/FormLogin.vue";
import FormTokenLogin from "@/components/Forms/FormTokenLogin.vue";
import FormPasswordRecovery from "@/components/Forms/FormPasswordRecovery.vue";
import ChooseLanguage from "@/components/ChooseLanguage.vue";
export default Vue.extend({
    components: {
        ChooseLanguage,
        AppWelcome,
        CommonHeader,
        CommonButton,
        CommonLabel,
        CommonVarchar,
        FormLogin,
        FormTokenLogin,
        FormPasswordRecovery,
    },
    props: {
        useTokenLogin: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [loginMixin],
});
