import Vue from "vue";
import { ModalSystem } from "@/components/ModalTypes";
import { ModalManagementMode } from "@/enums";
import { localizationService } from "@/main";
export default Vue.extend({
    props: {
        responseSystem: {
            type: Array,
        },
    },
    components: {
        ModalSystem,
    },
    data() {
        return {
            systemFilterModel: {},
            debounceTimeout: {},
            systemSettingFiltered: [],
            ModalManagementMode,
            systemSetting: {},
            localizationService,
            code: "",
            value: "",
            modalManagementMode: "",
        };
    },
    watch: {
        responseSystem: {
            handler() {
                this.systemSettingFiltered = this.lodash.cloneDeep(this.responseSystem);
                this.applyFilter();
            },
            deep: true
        },
    },
    mounted() {
        this.$emit("setFormTitle", this.localizationService.DashLoc('impostazioni_sistema'));
        this.systemFilterModel = {
            code: "",
            value: ""
        };
    },
    methods: {
        theFilter(item) {
            let toReturn = true;
            var vueinstance = this;
            this.lodash.forEach(this.systemFilterModel, function (f, k) {
                toReturn = toReturn && (f.trim() == "" || (item[k].toLowerCase().includes(vueinstance.systemFilterModel[k].toLowerCase())));
            });
            return toReturn;
        },
        startDebounce() {
            if (this.debounceTimeout) {
                clearTimeout(this.debounceTimeout);
            }
            this.debounceTimeout = setTimeout(() => {
                this.applyFilter();
            }, 500); // delay
        },
        applyFilter() {
            this.systemSettingFiltered = this.lodash.filter(this.responseSystem, this.theFilter);
        },
        openModal(res, mode) {
            this.modalManagementMode = mode;
            this.$nextTick(() => {
                this.$refs.showModal.openModal(res);
            });
        },
        systemSettingByModal(systemSetting, mode) {
            switch (mode) {
                case ModalManagementMode.EDIT:
                    this.$emit("editSystemSettings", systemSetting);
                    break;
                case ModalManagementMode.ADD:
                case ModalManagementMode.DUPLICATE:
                    this.$emit("addSystemSettings", systemSetting);
                    break;
                case ModalManagementMode.DELETE:
                    this.$emit("deleteSystemSettings", systemSetting);
                    break;
            }
            return;
        },
    },
});
