import Vue from "vue";
import DynamicPropertyType from "./DynamicPropertyType.vue";
export default Vue.extend({
    components: {
        DynamicPropertyType,
    },
    props: {
        field: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    watch: {
        field() {
            const dataStr = localStorage.getItem('searchData');
            if (dataStr) {
                const data = JSON.parse(dataStr);
                const interval = setInterval(() => this.$nextTick(() => {
                    var elements = document.querySelectorAll('[codetag="' + data.code_tag + '"]');
                    let findedElement = false;
                    elements.forEach(function (element) {
                        if (element.innerHTML.includes(data.searchModel)) {
                            findedElement = true;
                            element.innerHTML = element.innerHTML.replaceAll(data.searchModel, `<mark>${data.searchModel}</mark>`);
                        }
                    });
                    if (findedElement) {
                        localStorage.removeItem("searchData");
                        clearInterval(interval);
                    }
                }), 3500);
            }
        }
    }
});
