import Vue from "vue";
import CommonAttachment from "./CommonAttachment.vue";
export default Vue.extend({
    components: {
        CommonAttachment,
    },
    data() {
        return {
            modelFile: [],
        };
    },
    props: {
        accept: {
            type: String,
            default: "",
        },
        browseText: {
            type: String,
            default: "",
        },
        id: {
            type: String,
            default: "",
        },
        name: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: "",
        },
        dropPlaceholder: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        state: {
            type: Boolean,
            default: null,
        },
    },
    methods: {
        handleInput(file) {
            this.$emit("input", this.modelFile);
        },
        removeAttachment(fileIndex) {
            this.modelFile.splice(fileIndex, 1);
            if (this.modelFile.length == 0) {
                this.$refs["attachments"].reset();
            }
        },
    },
});
