import Vue from "vue";
import homeMixin from '@/mixins/homeMixin';
import smartFormHeaderMixin from "@/mixins/smartFormHeaderMixin";
import leftPanel from './components/LeftPanel.vue';
import "@/views/templates/hallways/css/hallways.scss";
import { SSO_KEY } from "@/constants";
import { webRestService } from "@/main";
import { TagTypeEnum } from "@/enums";
import LangIcon from "@/components/LangIcon.vue";
import CommonHeader from "@/components/commons/CommonHeader.vue";
import CommonModal from "@/components/commons/CommonModal.vue";
import SearchBar from "@/components/commons/SearchBar.vue";
import SmartformFooter from "@/components/SmartformFooter.vue";
export default Vue.extend({
    mixins: [
        homeMixin,
        smartFormHeaderMixin
    ],
    components: {
        SmartformFooter,
        CommonHeader,
        CommonModal,
        SearchBar,
        LangIcon,
        leftPanel
    },
    data() {
        return {
            title: "",
            subTitle: "",
            language: "",
            languageTagMap: {}
        };
    },
    props: {
        templateName: String,
        templateConfig: {}
    },
    computed: {
        otpCode() {
            if (this.$store.getters[SSO_KEY.GET_STORE_OTP_CODE]) {
                return this.$store.getters[SSO_KEY.GET_STORE_OTP_CODE];
            }
            else {
                let cookieName = `${SSO_KEY.COOKIE_OTP_CODE}_${this.customerCode}`;
                let otp = this.$cookies.get(cookieName);
                if (otp) {
                    this.$store.dispatch(SSO_KEY.SET_STORE_OTP_CODE, atob(otp));
                }
                return this.$store.getters[SSO_KEY.GET_STORE_OTP_CODE];
            }
        }
    },
    methods: {
        setLanguage(language, reload) {
            this.$refs.smartForm.setLanguage(language, reload);
        },
    },
    watch: {
        formCode(newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }
            webRestService.getFormEntryHeader(this.formCode, this.customerCode, this.otpCode).then((response) => {
                this.updateHeaderFields(response.form_fields, true);
                response.children.forEach((children) => {
                    if (children.tag_type === TagTypeEnum.FORM_LANG) {
                        const theParam = children.params.find((param) => {
                            return param.params_type === 'FORM_LANG';
                        });
                        if (!!theParam) {
                            this.languageTagMap[theParam.lang_code] = children.code_tag;
                        }
                    }
                });
                const selectedLang = this.selectedLanguage;
                if (!!selectedLang && selectedLang in this.languageTagMap) {
                    webRestService.getFormEntryHeader(this.languageTagMap[selectedLang], this.customerCode, this.otpCode).then((res) => {
                        this.updateHeaderFields(res.form_fields, false, selectedLang);
                    });
                }
            });
        },
        selectedLanguage(newLang, oldLang) {
            if (newLang === oldLang) {
                return;
            }
            if (newLang in this.languageTagMap) {
                webRestService.getFormEntryHeader(this.languageTagMap[newLang], this.customerCode, this.otpCode).then((response) => {
                    this.updateHeaderFields(response.form_fields, false, newLang);
                });
            }
        }
    }
});
