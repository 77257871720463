export var Locales;
(function (Locales) {
    Locales["EN"] = "en";
    Locales["IT"] = "it";
    Locales["DE"] = "de";
    Locales["FR"] = "fr";
    Locales["EL"] = "el";
    Locales["ES"] = "es";
    Locales["NL"] = "nl";
})(Locales || (Locales = {}));
export const LOCALES = [
    { value: Locales.EN, caption: 'English' },
    { value: Locales.IT, caption: 'Italiano' },
    { value: Locales.DE, caption: 'Deutsch' },
    { value: Locales.FR, caption: 'Français' },
    { value: Locales.EL, caption: 'Ελληνικά' },
    { value: Locales.ES, caption: 'Español' },
    { value: Locales.NL, caption: 'Nederlands' }
];
