import { render, staticRenderFns } from "./AppWelcome.vue?vue&type=template&id=3d75117e&scoped=true&"
import script from "./AppWelcome.vue?vue&type=script&lang=ts&"
export * from "./AppWelcome.vue?vue&type=script&lang=ts&"
import style0 from "./AppWelcome.vue?vue&type=style&index=0&id=3d75117e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d75117e",
  null
  
)

export default component.exports