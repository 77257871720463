import Vue from "vue";
import homeMixin from '@/mixins/homeMixin';
import smartFormHeaderMixin from "@/mixins/smartFormHeaderMixin";
import SelectLocale from "@/components/SelectLocale.vue";
import HtmlHeaderType from "@/components/FieldTypes/HtmlHeaderType.vue";
import "@/views/templates/headerSearch/css/headerSearch.scss";
import { BackgroundTypes, TagTypeEnum } from "@/enums";
import { webRestService } from "@/main";
import { SSO_KEY } from "@/constants";
import SearchBar from "@/components/commons/SearchBar.vue";
import LangIcon from "@/components/LangIcon.vue";
export default Vue.extend({
    mixins: [
        homeMixin,
        smartFormHeaderMixin
    ],
    components: {
        LangIcon,
        SearchBar,
        SelectLocale,
        HtmlHeaderType
    },
    data() {
        return {
            title: "",
            subTitle: "",
            language: "",
            languageTagMap: {}
        };
    },
    props: {
        templateName: String,
        templateConfig: {}
    },
    computed: {
        otpCode() {
            if (this.$store.getters[SSO_KEY.GET_STORE_OTP_CODE]) {
                return this.$store.getters[SSO_KEY.GET_STORE_OTP_CODE];
            }
            else {
                let cookieName = `${SSO_KEY.COOKIE_OTP_CODE}_${this.customerCode}`;
                let otp = this.$cookies.get(cookieName);
                if (otp) {
                    this.$store.dispatch(SSO_KEY.SET_STORE_OTP_CODE, atob(otp));
                }
                return this.$store.getters[SSO_KEY.GET_STORE_OTP_CODE];
            }
        },
        headerStyle() {
            const templateConfig = this.templateConfig;
            switch (templateConfig.backgroundType) {
                case BackgroundTypes.IMG:
                    try {
                        const background = require(`@/assets/backgrounds/${templateConfig.backgroundImg}`);
                        return {
                            backgroundImage: `url("${background}")`,
                            backgroundAttachment: "fixed",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover"
                        };
                    }
                    catch (e) {
                    }
                    break;
                case BackgroundTypes.COLOR:
                    return {
                        backgroundColor: templateConfig.backgroundColor
                    };
                case BackgroundTypes.GRADIENT:
                    const backgroundGradient = templateConfig.backgroundGradient;
                    if (!!backgroundGradient) {
                        try {
                            return {
                                background: `linear-gradient(${backgroundGradient.angle}deg, ${backgroundGradient.from} 0%, ${backgroundGradient.to} 100%) !important`,
                                backgroundAttachment: "fixed",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover"
                            };
                        }
                        catch (e) {
                        }
                    }
                    break;
                case BackgroundTypes.NONE:
                    break;
                default:
                    break;
            }
            return '';
        }
    },
    watch: {
        formCode(newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }
            webRestService.getFormEntryHeader(this.formCode, this.customerCode, this.otpCode).then((response) => {
                this.updateHeaderFields(response.form_fields, true);
                response.children.forEach((children) => {
                    if (children.tag_type === TagTypeEnum.FORM_LANG) {
                        const theParam = children.params.find((param) => {
                            return param.params_type === 'FORM_LANG';
                        });
                        if (!!theParam) {
                            this.languageTagMap[theParam.lang_code] = children.code_tag;
                        }
                    }
                });
                const selectedLang = this.selectedLanguage;
                if (!!selectedLang && selectedLang in this.languageTagMap) {
                    webRestService.getFormEntryHeader(this.languageTagMap[selectedLang], this.customerCode, this.otpCode).then((res) => {
                        this.updateHeaderFields(res.form_fields, false, selectedLang);
                    });
                }
            });
        },
        selectedLanguage(newLang, oldLang) {
            if (newLang === oldLang) {
                return;
            }
            if (newLang in this.languageTagMap) {
                webRestService.getFormEntryHeader(this.languageTagMap[newLang], this.customerCode, this.otpCode).then((response) => {
                    this.updateHeaderFields(response.form_fields, false, newLang);
                });
            }
        }
    },
    methods: {
        setLanguage(language, reload) {
            this.$refs.smartForm.setLanguage(language, reload);
        }
    }
});
