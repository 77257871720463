import { SET_AUTH_TOKEN, SET_OTP_CODE, SET_NEXT_ROUTE, SET_IS_OTP_CODE_TO_SEND } from '../mutation-types';
const mutations = {
    [SET_AUTH_TOKEN](state, value) {
        state.authToken = value;
    },
    [SET_OTP_CODE](state, value) {
        state.otpCode = value;
    },
    [SET_NEXT_ROUTE](state, value) {
        state.nextRoute = value;
    },
    [SET_IS_OTP_CODE_TO_SEND](state, value) {
        state.isOtpCodeToSend = value;
    }
};
export default mutations;
