import { buttonMappingService, globalEventBus, localizationService } from "@/main";
import swal from "sweetalert2";
export default class DialogService {
    static _instance;
    constructor() {
    }
    static get Instance() {
        return this._instance || (this._instance = new this());
    }
    showErrorDiagErrorMessage(errorMessage) {
        let modalObject = {};
        if (errorMessage && !errorMessage.msg.includes('forms0005')) {
            let buttonMapping = buttonMappingService.buttonMapping(errorMessage?.msg);
            if (buttonMapping) {
                modalObject = buttonMapping;
            }
        }
        modalObject.text = errorMessage?.msg ? localizationService.ErrorLoc(errorMessage.msg) : localizationService.CommonLoc('errore_recupero_dati').toString();
        modalObject.icon = errorMessage?.type ? errorMessage.type : "error";
        modalObject.title = errorMessage?.title ? errorMessage.title : localizationService.CommonLoc('attenzione').toString();
        modalObject.confirmButtonText = modalObject.confirmButtonText ? modalObject.confirmButtonText : localizationService.CommonLoc('ricarica_form').toString();
        modalObject.cancelButtonText = modalObject.cancelButtonText ? modalObject.cancelButtonText : localizationService.CommonLoc('chiudi').toString();
        modalObject.allowOutsideClick = false;
        swal.fire(modalObject).then((result) => {
            if (result.isConfirmed) {
                if (modalObject.redirectURL) {
                    globalEventBus.$emit('redirect-url', modalObject.redirectURL);
                }
                else {
                    globalEventBus.$emit('reload-form');
                }
            }
        });
    }
    showWarningDiagErrorMessage(errorMessage) {
        let title, msg, type;
        title = errorMessage?.title ? errorMessage.title : localizationService.CommonLoc('attenzione');
        msg = errorMessage?.msg ? localizationService.ErrorLoc(errorMessage.msg) : localizationService.CommonLoc('errore_recupero_dati');
        type = errorMessage?.type ? errorMessage.type : "warning";
        return this.showDiag(title, msg, type);
    }
    showErrorDiag(title, msg) {
        return this.showDiag(title, msg, 'error');
    }
    showWarningDiag(title, msg) {
        return this.showDiag(title, msg, 'warning');
    }
    showDiag(title, msg, type) {
        return swal.fire({
            icon: type,
            title,
            text: msg,
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: 'Chiudi',
            allowOutsideClick: false
        });
    }
}
