import Vue from 'vue';
export default Vue.extend({
    props: {
        headerElement: {
            type: Object
        }
    },
    computed: {
        getUrl() {
            return (url) => {
                if (!/^http?:\/\//i.test(url) && !/^https?:\/\//i.test(url)) {
                    return `http://${url}`;
                }
                return url;
            };
        }
    }
});
