import Vue from "vue";
import CommonVarchar from "./CommonVarchar.vue";
import CommonTimepicker from "@/components/commons/CommonTimepicker.vue";
import { localizationService } from "@/main";
export default Vue.extend({
    components: {
        CommonTimepicker,
        CommonVarchar,
    },
    data() {
        return {
            localizationService,
            modelText: "",
        };
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        showTimePicker: {
            type: Boolean,
            default: undefined,
        },
        state: {
            type: Boolean,
            default: null,
        },
        datepickerName: {
            type: String,
            default: "",
        },
        dateFormatOptions: {
            type: Object,
            default: () => {
                return {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                };
            },
        },
        id: {
            type: String,
            default: "",
        },
        locale: {
            type: [Array, String],
            default: "it",
        },
        maxDate: {
            type: [Date, String],
            default: "",
        },
        minDate: {
            type: [Date, String],
            default: "",
        },
        modelValue: {
            type: [Date, String],
            default: "",
        },
        placeholder: {
            type: String,
            default: "",
        },
        startWeekday: {
            type: [Number, String],
            default: 1,
        },
    },
    methods: {
        handleDatepickerInput(event) {
            this.$emit("datepicker-input", event);
        },
        handleInput(event) {
            this.$emit("input", event);
        },
        handleTimepickerInput(event) {
            this.$emit("input-timepicker", event);
        },
    },
    watch: {
        modelText(newValue) {
            this.handleDatepickerInput(newValue);
        },
    },
});
