import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowDown, faArrowLeft, faCheckCircle, faChevronDown, faFileExcel, faPlus, } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
library.add(faArrowLeft, faPlus, faCheckCircle, faFileExcel, faArrowDown, faChevronDown, faTrashAlt);
export default Vue.extend({
    name: "CustomButton",
    props: {
        legacy: {
            type: Boolean,
            default: true,
        },
        isTimeline: {
            type: Boolean,
            default: false,
        },
        text: {
            type: String,
        },
        title: {
            type: String,
            default: ''
        },
        classButton: {
            type: String,
            default: ''
        },
        showLabel: {
            type: Boolean,
            default: true,
        },
        showTooltip: {
            type: Boolean,
            default: false,
        },
        tooltipText: {
            type: String,
            default: "",
        },
        tooltipVariant: {
            type: String,
            default: "primary",
        },
        tooltipPlacement: {
            type: String,
            default: "top",
        },
        buttonType: {
            type: String,
            default: "button",
        },
        showCollapse: {
            type: Boolean,
            default: false,
        },
        typeButton: { type: String, default: "" },
        variant: {
            type: String,
            default: "secondary",
        },
        size: {
            type: String,
            default: "md",
        },
        href: {
            type: String,
            default: null,
        },
        target: {
            type: String,
            default: "_blank",
        },
        rel: {
            type: String,
            default: "noopener noreferrer",
        },
        disabled: {
            type: Boolean,
            default: null,
        },
        icon: {
            type: Boolean,
            default: false,
        },
        iconClass: {
            type: String,
            default: "",
        },
        isConfirm: {
            type: Boolean,
            default: false,
        },
        isCancel: {
            type: Boolean,
            default: false,
        },
        isCollapse: {
            type: Boolean,
            default: false,
        },
        isAction: {
            type: Boolean,
            default: false,
        },
        isPrev: {
            type: Boolean,
            default: false,
        },
        isNext: {
            type: Boolean,
            default: false,
        },
        isAdd: {
            type: Boolean,
            default: false,
        },
        open: {
            type: Boolean,
            default: false,
        },
        animateOnHover: {
            type: Boolean,
            default: false,
        },
        animateOnHoverDirection: {
            type: String,
            default: "right",
        },
        isTitle: {
            type: Boolean,
            default: false,
        },
        isDownload: {
            type: Boolean,
            default: false,
        },
        isDownloadExcel: {
            type: Boolean,
            default: false,
        },
        outline: {
            type: Boolean,
        },
    },
});
