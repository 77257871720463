import Vue from "vue";
import PropertyType from "@/components/FieldTypes/PropertyType.vue";
import ButtonType from "@/components/FieldTypes/ButtonType.vue";
import { localizationService } from "@/main";
export default Vue.extend({
    data() {
        return {
            localizationService,
            whatToShow: 0,
            options: []
        };
    },
    mounted() {
        this.options = [
            { text: this.localizationService.CommonLoc('si'), value: 2 },
            { text: this.localizationService.CommonLoc('no'), value: 1 },
        ];
    },
    props: {
        field: {
            type: Object,
        },
        properties: {
            type: Array
        }
    },
    methods: {
        getProperty(propertyCode) {
            let theProperty = this.lodash.find(this.properties, (p) => p.property_code == propertyCode);
            return theProperty;
        },
        changed() {
            let theEvent = "";
            switch (this.whatToShow) {
                case 1:
                    this.$emit("answeredFurtherQuestions", {
                        "id": this.field.id,
                        "whatSelected": this.whatToShow,
                        "fields": this.field.value.nofields,
                        "text": this.field.value.greetings
                    });
                    break;
                case 2:
                    this.$emit("answeredFurtherQuestions", {
                        "id": this.field.id,
                        "whatSelected": this.whatToShow,
                        "fields": this.field.value.fields,
                        "text": this.field.value.yes
                    });
                    break;
            }
        }
    },
    computed: {},
    watch: {},
    components: {
        PropertyType,
        ButtonType,
    }
});
