var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-alert',{staticClass:"common-alert d-flex text-left rounded-sm",class:[
    {
      center: _vm.center,
      compressed: _vm.compressed,
      legacy: _vm.legacy,
      vertical: _vm.vertical,
    } ],attrs:{"variant":_vm.variant,"show":""}},[(_vm.title || _vm.subtitle)?_c('div',{staticClass:"common-alert__content d-flex align-items-center"},[_vm._t("before-content"),(_vm.variantIcon && _vm.showIcon)?_c('div',{staticClass:"common-alert__icon mt-0 mr-3"},[_c('common-icon',{attrs:{"inAlert":true,"icon":_vm.variantIcon,"flat":""}})],1):_vm._e(),(_vm.title)?_c('h5',{staticClass:"common-alert__title mb-1",class:[
        { 'text-primary': _vm.variant === 'info' },
        { 'text-success': _vm.variant === 'success' },
        { 'text-warning-custom': _vm.variant === 'warning' },
        { 'text-danger': _vm.variant === 'danger' } ]},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),(_vm.subtitle)?_c('p',{staticClass:"common-alert__subtitle mb-0"},[_vm._v(_vm._s(_vm.subtitle))]):_vm._e(),_vm._t("after-content")],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }