import Vue from "vue";
//All possible dynamicPropertyType components
import CommonDate from "@/components/commons/CommonDate.vue";
import CommonEmail from "@/components/commons/CommonEmail.vue";
import CommonFaq from "@/components/commons/CommonFaq.vue";
import CommonFile from "@/components/commons/CommonFile.vue";
import CommonHidden from "@/components/commons/CommonHidden.vue";
import CommonHtml from "@/components/commons/CommonHtml.vue";
import CommonLabel from "@/components/commons/CommonLabel.vue";
import CommonRadio from "@/components/commons/CommonRadio.vue";
import CommonSelect from "@/components/commons/CommonSelect.vue";
import CommonTextarea from "@/components/commons/CommonTextarea.vue";
import CommonVarchar from "@/components/commons/CommonVarchar.vue";
export default Vue.extend({
    components: {
        CommonDate,
        CommonEmail,
        CommonFaq,
        CommonFile,
        CommonHidden,
        CommonHtml,
        CommonLabel,
        CommonRadio,
        CommonSelect,
        CommonTextarea,
        CommonVarchar,
    },
    props: {
        label: {
            type: String,
            default: "",
        },
        labelInfo: {
            type: Object,
            default: () => {
                return {};
            },
        },
        modelValue: {
            type: [Array, Date, String],
            default: undefined,
        },
        options: {
            type: Array,
            default: () => {
                return [
                    {
                        value: {},
                    },
                ];
            },
        },
        accept: {
            type: String,
            default: "",
        },
        browseText: {
            type: String,
            default: "",
        },
        id: {
            type: String,
            default: undefined,
        },
        autocomplete: {
            type: String,
            default: undefined,
        },
        description: {
            type: String,
            default: undefined,
        },
        fieldType: {
            type: String,
            default: "",
        },
        maxLength: {
            type: String,
            default: undefined,
        },
        minLength: {
            type: String,
            default: undefined,
        },
        maxRows: {
            type: String,
            default: undefined,
        },
        name: {
            type: String,
            default: undefined,
        },
        placeholder: {
            type: String,
            default: undefined,
        },
        dropPlaceholder: {
            type: String,
            default: "",
        },
        rows: {
            type: String,
            default: undefined,
        },
        validationErrors: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        horizontal: {
            type: Boolean,
            default: true,
        },
        inputState: {
            type: Boolean,
            default: null,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        showTimePicker: {
            type: Boolean,
            default: undefined,
        },
        state: {
            type: Boolean,
            default: null,
        },
        datepickerName: {
            type: String,
            default: "",
        },
        locale: {
            type: [Array, String],
            default: "it",
        },
        maxDate: {
            type: [Date, String],
            default: "",
        },
        minDate: {
            type: [Date, String],
            default: "",
        },
        startWeekday: {
            type: [Number, String],
            default: 1,
        },
        title: {
            type: String,
            default: "",
        },
        subtitle: {
            type: String,
            default: "",
        },
        icon: {
            type: Object,
            default: () => {
                return {};
            },
        },
        field: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    computed: {
        propertyTypeFormatted() {
            return this.fieldType?.toLowerCase();
        },
        isFile() {
            return this.fieldType?.toLowerCase() === "file";
        },
        isInput() {
            return (this.fieldType?.toLowerCase() === "varchar" ||
                this.fieldType?.toLowerCase() === "textarea" ||
                this.fieldType?.toLowerCase() === "hidden" ||
                this.fieldType?.toLowerCase() === "email");
        },
        isDatepicker() {
            return this.fieldType?.toLowerCase() === "date";
        },
    },
    methods: {
        handleInput(event) {
            this.$emit("input", {
                value: event,
                fieldType: this.fieldType,
            });
        },
        handleInputTimepicker(event) {
            this.$emit("input", {
                value: event,
                fieldType: this.fieldType + "-with-timepicker",
            });
        },
    },
});
