import Vue from "vue";
import { localizationService } from "@/main";
export default Vue.extend({
    props: {
        showModal: Boolean,
    },
    data() {
        return {
            internalShowModal: false,
            tagFormParamsString: {},
            localizationService
        };
    },
    methods: {
        openModal(tagFormParams) {
            this.internalShowModal = true;
            this.lodash.forOwn(tagFormParams, (value, key) => {
                if (Array.isArray(value)) {
                    this.tagFormParamsString[key] = value.join(", ");
                }
                else if (typeof value === 'boolean') {
                    this.tagFormParamsString[key] = String(value);
                }
                else {
                    this.tagFormParamsString[key] = value;
                }
            });
        }
    }
});
