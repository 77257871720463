import Vue from 'vue';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faMessage, faX } from "@fortawesome/free-solid-svg-icons";
import { webRestService } from "@/main";
library.add(faMessage);
library.add(faX);
export default Vue.extend({
    data() {
        return {
            webRestService,
            collapse: false,
            chatLogo: '',
            position: '',
            urlChat: undefined
        };
    },
    props: {
        liveConfig: {
            type: Object
        },
        codeCustomer: {
            type: String,
            default: ""
        }
    },
    methods: {
        openEbbyChat() {
            webRestService.openEbbyChat(this.codeCustomer).then(response => {
                this.urlChat = response.url;
            });
        }
    },
    mounted() {
        const handleMessage = (e) => {
            if (e.data === "closeDialogEbbyChat" || e.message === "closeDialogEbbyChat") {
                this.collapse = true;
            }
        };
        window.addEventListener('message', handleMessage);
    },
    watch: {
        liveConfig: {
            handler(newValue, oldValue) {
                this.chatLogo = newValue.chatIcon.value;
                this.position = newValue.align ? newValue.align : 'LEFT';
            },
            deep: true
        }
    }
});
