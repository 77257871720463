import Vue from "vue";
import { globalEventBus } from '@/main';
export default Vue.extend({
    methods: {
        setLoading(flag) {
            this.isLoading = flag;
        }
    },
    mounted() {
        globalEventBus.$on('isLoading', (flag) => {
            this.setLoading(flag);
        });
    },
    data() {
        return {
            isLoading: false
        };
    }
});
