import { AuthType, BackgroundTypes } from '@/enums';
export function isASurvey(authType) {
    return authType.includes(AuthType.SURVEY);
}
export function validateEmail(email) {
    let regex = /^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]*[a-zA-Z0-9]+\.)+[a-zA-Z]{2,63}$/;
    return regex.test(email?.toLowerCase());
}
export function getLogosHTML(response, getImageOfIndex = undefined) {
    let htmlToInject;
    if (response.logo_params && response.logo_params.length > 0) {
        htmlToInject = '<div class="logos row no-gutters">';
        let index = 0;
        const firstImageRange = getImageOfIndex !== undefined ? getImageOfIndex : 0;
        const lastImageRange = getImageOfIndex !== undefined ? getImageOfIndex + 1 : response.logo_params.length;
        response.logo_params.slice(firstImageRange, lastImageRange).forEach((param) => {
            try {
                let logo = require("@/assets/logos/" + param.value);
                htmlToInject += '<div class="col';
                if (!index) {
                    htmlToInject += ' text-left logos__single logos__main ';
                }
                else if (index === response.logo_params.length - 1) {
                    htmlToInject += ' text-right logos__single logos__client ';
                }
                if (param.alt && param.alt.length > 0) {
                    htmlToInject += '"><img class="img-fluid" style="max-height: 65px;" alt="' + param.alt + '" class="';
                }
                else {
                    htmlToInject += '"><img class="img-fluid" style="max-height: 65px;" alt="" role="presentation" class="';
                }
                if (!index) {
                    htmlToInject += ' mr-auto ';
                }
                else if (index !== response.logo_params.length - 1) {
                    htmlToInject += ' m-auto ';
                }
                else {
                    htmlToInject += ' ml-auto ';
                }
                index++;
                htmlToInject += '" src="' + logo + '"/></div>';
            }
            catch (error) {
                console.error("Header Logo non trovato", error);
            }
        });
        htmlToInject += '</div>';
        return htmlToInject;
    }
    return false;
}
export function handleBackground(response) {
    let background = require("@/assets/default-bg.png");
    switch (response.background_type) {
        case BackgroundTypes.COLOR:
            if (response.background_color) {
                try {
                    document.body.setAttribute("style", "background-color: " + response.background_color + " !important");
                }
                catch (e) {
                }
            }
            break;
        case BackgroundTypes.GRADIENT:
            if (response.background_gradient) {
                try {
                    document.body.setAttribute("style", "background: linear-gradient(" + response.background_gradient.angle + "deg, " + response.background_gradient.from + " 0%, " + response.background_gradient.to + " 100%) !important");
                }
                catch (e) {
                }
            }
            break;
        case BackgroundTypes.IMG:
        default:
            if (response.background_img) {
                try {
                    background = require("@/assets/backgrounds/" +
                        response.background_img);
                }
                catch (e) {
                    console.error(e);
                }
            }
            document.body.style.backgroundImage = "url('" + background + "')";
    }
}
export function handleCustomerLogo(response) {
    if (response.logo) {
        try {
            return require("@/assets/logos/" + response.logo);
        }
        catch (e) {
            console.error("Customer Logo non trovato", e);
        }
    }
    return '';
}
export function handleFavicon(response) {
    let favicon = require("@/assets/favicons/smartform.ico");
    if (response.favicon) {
        try {
            favicon = require("@/assets/favicons/" + response.favicon);
        }
        catch (e) {
            console.error("Favicon non trovata", e);
        }
    }
    return favicon;
}
