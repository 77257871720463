import Vue from "vue";
import notSupportedMixin from "@/mixins/notSupportedMixin";
import leftPanel from './components/LeftPanel.vue';
export default Vue.extend({
    mixins: [notSupportedMixin],
    components: {
        leftPanel
    },
    props: {
        templateConfig: {}
    }
});
