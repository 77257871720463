import Vue from "vue";
import App from "./App.vue";
import router from "@/router/index";
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import vueHeadful from 'vue-headful';
import store from '@/store';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import Multiselect from 'vue-multiselect';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheckSquare, faMagnifyingGlass, faPlusSquare, faQuestionCircle, faSquare, faTrash, faUserLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import CookieLaw from 'vue-cookie-law';
import VueI18n from 'vue-i18n';
import { defaultLocale, messages } from "@/i18n";
import WebRestService from "@/services/WebRestService";
import LocalizationService from "@/services/LocalizationService";
import GeneralFunctions from "@/services/GeneralFunctions";
import ButtonMappingService from "@/services/ButtonMappingService";
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import VueRouter from "vue-router";
import "prismjs";
import "prismjs/themes/prism.css";
import DialogService from "@/services/DialogService";
const webRestService = WebRestService.Instance;
const dialogService = DialogService.Instance;
const localizationService = LocalizationService.Instance;
const generalFunctions = GeneralFunctions.Instance;
const buttonMappingService = ButtonMappingService.Instance;
const defaultLanguage = "it";
export const globalEventBus = new Vue();
library.add(faPlusSquare);
library.add(faMagnifyingGlass);
library.add(faTrash);
library.add(faQuestionCircle);
library.add(faCheckSquare);
library.add(faSquare);
library.add(faUserLock);
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueI18n);
Vue.use(VueRouter);
Vue.use(VueLodash, { lodash: lodash });
Vue.component('vue-headful', vueHeadful);
Vue.component('multiselect', Multiselect);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('cookie-law', CookieLaw);
Vue.component('vue-typeahead-bootstrap', VueTypeaheadBootstrap);
Vue.prototype.moment = moment;
Vue.prototype.momentTimezone = momentTimezone;
const i18n = new VueI18n({
    messages,
    locale: defaultLocale,
    fallbackLocale: defaultLocale
});
new Vue({
    router,
    i18n,
    render: h => h(App),
    store
}).$mount("#app");
export { i18n, webRestService, dialogService, localizationService, generalFunctions, buttonMappingService, store, defaultLanguage, router };
