import Vue from "vue";
import { localizationService } from "@/main";
import { AuthType } from "@/enums";
export default Vue.extend({
    props: {
        step: Object,
        variant: {
            type: String,
            default: "info"
        },
        tokenType: {
            type: Number
        },
        details: {
            type: Object
        }
    },
    mounted() {
    },
    methods: {
        clicked() {
            this.$emit("onFormSubmit", this.details.action);
        }
    },
    computed: {
        showButton() {
            return this.step.children.length == 0 && (this.lodash.filter(this.step.form_fields, (ff) => ff.type == "FURTHER_QUESTIONS").length === 0 && this.lodash.filter(this.step.form_fields, (ff) => ff.type == "PROPERTY" || ff.type == "EMAIL").length > 0);
        }
    },
    data() {
        return {
            localizationService,
            AuthType,
        };
    }
});
