import Vue from "vue";
import { dialogService, localizationService, webRestService } from "@/main";
const freeAuthTypes = ["FREE", "FREE_CAPTCHA", "DATACOLLECTION"];
export default Vue.extend({
    props: {},
    data() {
        return {
            internalShowModal: false,
            showNewParameter: false,
            parameterKey: "",
            parameterValue: "",
            formMappings: [],
            formContext: {},
            account: {},
            tagForm: null,
            parameters: {},
            localizationService,
            authType: null,
            route: null,
            freeAuthTypes,
            selectableAuthTypes: [...freeAuthTypes, 'SSO', 'SURVEY'],
            uneditableParameters: [],
            formAction: "",
            formMessage: ""
        };
    },
    computed: {
        authTypes() {
            if (this.tagForm == null) {
                return [];
            }
            return this.tagForm.form_params.auth_type.filter(authType => this.selectableAuthTypes.includes(authType));
        },
        showMappings() {
            return this.authType != null && !this.freeAuthTypes.includes(this.authType);
        },
        disableRedirect() {
            return this.authTypes.length == 0;
        },
    },
    watch: {
        authType(newValue, oldValue) {
            if (newValue == null || newValue === oldValue) {
                return;
            }
            if (this.freeAuthTypes.includes(newValue)) {
                this.route = null;
            }
            if (this.formMappings.length > 1) {
                this.route = this.formMappings[0];
            }
        }
    },
    methods: {
        openModal(formContext, formMappings, tagForm, account) {
            this.internalShowModal = true;
            this.formContext = formContext;
            this.formMappings = formMappings;
            this.tagForm = tagForm;
            this.authType = null;
            this.route = null;
            if (this.tagForm.form_params.auth_type.length > 0) {
                this.authType = this.tagForm.form_params.auth_type[0];
            }
            const { email, name: nome, surname: cognome } = account;
            this.account = account;
            this.parameters = { email, nome, cognome };
            this.uneditableParameters = Object.keys(this.parameters);
            this.formAction = "";
            this.formMessage = "";
        },
        processString(text) {
            return text ? text : "--";
        },
        handleOk(event) {
            event.preventDefault();
            if (this.authType == null) {
                dialogService.showErrorDiag(localizationService.DashLoc('Errore'), this.getErrorText());
                return;
            }
            if (this.freeAuthTypes.includes(this.authType)) {
                const url = this.formContext.client_url + this.formContext.code_form;
                window.open(url);
                this.internalShowModal = false;
                return;
            }
            if (!this.canRedirect()) {
                dialogService.showErrorDiag(localizationService.DashLoc('Errore'), this.getErrorText());
                return;
            }
            const routeId = this.route?.id || null;
            webRestService.getSsoMessage(this.formContext.id, routeId, this.parameters).then(result => {
                this.formAction = result.url;
                this.formMessage = result.message;
                this.$nextTick().then(() => {
                    this.$refs.ssoForm.submit();
                    this.internalShowModal = false;
                });
            });
        },
        newParameter() {
            this.showNewParameter = true;
        },
        addParameter() {
            if (this.parameterValue.length) {
                try {
                    this.parameters[this.parameterKey] = JSON.parse(this.parameterValue);
                }
                catch (e) {
                    this.parameters[this.parameterKey] = this.parameterValue;
                }
            }
            else {
                this.parameters[this.parameterKey] = this.parameterValue;
            }
            this.parameterKey = "";
            this.parameterValue = "";
            this.showNewParameter = false;
        },
        deletableParameter(key) {
            return !this.uneditableParameters.includes(key);
        },
        deleteParameter(key) {
            Vue.delete(this.parameters, key);
        },
        canRedirect() {
            if (this.showMappings) {
                return !!this.route;
            }
            return !!this.authType;
        },
        getErrorText() {
            if (!this.authType) {
                return localizationService.DashLoc('Selezionare il tipo autenticazione');
            }
            if (!this.route) {
                return localizationService.DashLoc('Selezionare le impostazioni di routing');
            }
            return '';
        }
    }
});
