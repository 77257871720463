import { render, staticRenderFns } from "./SmartformFooter.vue?vue&type=template&id=51cb5175&scoped=true&"
import script from "./SmartformFooter.vue?vue&type=script&lang=ts&"
export * from "./SmartformFooter.vue?vue&type=script&lang=ts&"
import style0 from "./SmartformFooter.vue?vue&type=style&index=0&id=51cb5175&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51cb5175",
  null
  
)

export default component.exports