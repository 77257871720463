import Vue from "vue";
import HtmlHeaderType from '@/components/FieldTypes/HtmlHeaderType.vue';
export default Vue.extend({
    components: {
        HtmlHeaderType
    },
    props: {
        htmlHeaderList: {
            type: Array
        },
    }
});
