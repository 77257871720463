import { SET_CHAT_DATA } from '../mutation-types';
const mutations = {
    [SET_CHAT_DATA](state, value) {
        state.active = value.active;
        state.fondo = value.fondo;
        state.chatId = value.chatId;
        state.align = value.align;
        state.chatType = value.chatType;
        state.chatIcon = value.chatIcon;
        state.codCustomer = value.codCustomer;
    },
};
export default mutations;
