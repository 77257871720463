import Vue from "vue";
import VueRouter from "vue-router";
import TemplateLoader from "@/views/TemplateLoader.vue";
import Management from "@/views/Management.vue";
import AuthManagement from "@/components/AuthManagement.vue";
import NotFound from "@/views/NotFound.vue";
import SSOAuth from "@/components/SSOAuth.vue";
import UnauthorizedAccess from "@/views/UnauthorizedAccess.vue";
Vue.use(VueRouter);
const routes = [
    {
        path: '/admin/management',
        name: 'Management',
        component: Management,
        props: true,
        meta: { global: true }
    }, {
        path: '/admin/management/auth',
        name: 'AuthManagement',
        component: AuthManagement,
        props: true,
        meta: { global: true }
    }, {
        path: '/notfound',
        name: 'NotFound',
        component: NotFound,
        props: true,
        meta: { global: true }
    }, {
        path: '/unauthorized',
        name: 'UnAuthorized',
        component: UnauthorizedAccess,
        props: true,
        meta: { global: true }
    },
    {
        path: '/:customerCode/sso',
        name: 'SSOAuth',
        component: SSOAuth,
        props: true
    },
    {
        path: '/:customerCode',
        name: 'Home',
        component: TemplateLoader,
        meta: { isStarter: true },
        props: true
    }
];
const router = new VueRouter({
    routes
});
export default router;
