import Vue from "vue";
import { localizationService } from "@/main";
export default Vue.extend({
    data() {
        return {
            localizationService,
            modelText: "",
        };
    },
    props: {
        modelValue: {
            type: [String, Object],
            default: undefined,
        },
        autocomplete: {
            type: String,
            default: "off",
        },
        description: {
            type: String,
            default: "",
        },
        id: {
            type: String,
            default: "",
        },
        maxLength: {
            type: String,
            default: "3000",
        },
        maxRows: {
            type: String,
            default: "",
        },
        name: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: "",
        },
        rows: {
            type: String,
            default: "3",
        },
        validationErrors: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        state: {
            type: Boolean,
            default: null,
        },
    },
    computed: {
        isInvalid() {
            return (this.required && !this.modelText) || this.state === false;
        },
        hasReachedMaxLength() {
            return this.maxLength && this.modelText.length == this.maxLength;
        },
    },
    methods: {
        handleInput() {
            this.$emit("input", this.modelText);
        },
    },
    watch: {
        modelValue(newValue) {
            this.modelText = newValue;
        },
    },
});
