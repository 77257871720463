import Vue from "vue";
import CommonButton from "@/components/commons/CommonButton.vue";
import CommonVarchar from "@/components/commons/CommonVarchar.vue";
import loginMixin from "@/mixins/loginMixin";
import { localizationService } from "@/main";
export default Vue.extend({
    components: {
        CommonButton,
        CommonVarchar,
    },
    mixins: [loginMixin],
    data() {
        return {
            username: "",
            password: "",
            localizationService,
        };
    },
    props: {
        hideForgotPassword: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        handleSubmit(event) {
            event.preventDefault();
            event.stopPropagation();
            this.login(this.username, this.password);
        },
        handleForgotPassword() {
            this.$emit("forgot-password");
        },
    },
});
