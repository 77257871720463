import Vue from "vue";
import homeMixin from '@/mixins/homeMixin';
import { AuthType } from "@/enums";
import SelectLocale from "@/components/SelectLocale.vue";
import LangIcon from "@/components/LangIcon.vue";
import "@/views/templates/default2022/css/default2022.scss";
import SearchBar from "@/components/commons/SearchBar.vue";
import CommonAvatar from "@/components/commons/CommonAvatar.vue";
import SmartformFooter from "@/components/SmartformFooter.vue";
export default Vue.extend({
    mixins: [homeMixin],
    components: {
        SmartformFooter,
        SearchBar,
        SelectLocale,
        LangIcon,
        CommonAvatar
    },
    props: {
        templateName: String,
        templateConfig: {}
    },
    data() {
        return {
            language: "",
            AuthType: AuthType
        };
    },
    methods: {
        setLanguage(language) {
            this.$refs.smartForm.setLanguage(language, false);
        }
    }
});
