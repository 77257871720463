import Vue from "vue";
import { localizationService } from "@/main";
export default Vue.extend({
    props: {
        id: Number,
        title: String,
        text: String,
        legacy: {
            type: Boolean,
            default: true,
        },
    },
    mounted() { },
    methods: {
        openModal() {
            this.$bvModal.show("modale-field-info-" + this.id);
        },
    },
    computed: {
        tooltipOptions() {
            return {
                variant: "primary",
                html: true,
                id: this.id ?? "",
                title: this.title ?? "",
                text: this.text ?? "",
            };
        },
    },
    data() {
        return {
            localizationService,
            idPrefix: "field-info-",
        };
    },
});
