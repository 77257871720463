var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"common-icon",class:[
    { flat: _vm.flat },
    { 'in-alert': _vm.inAlert},
    { 'is-selection': _vm.isSelection },
    { 'is-selected': _vm.isSelected },
    { 'is-multi-selection': _vm.multiSelection } ],style:(!_vm.active ? _vm.iconStyle : 'color: #ffffff;')},[(_vm.isSelection)?_c('div',{staticClass:"common-icon__icon-selection"},[_c('font-awesome-icon',{attrs:{"icon":_vm.multiSelection
          ? _vm.isSelected
            ? ['fas', 'check-square']
            : ['fas', 'square']
          : _vm.isSelected
          ? ['fas', 'check-circle']
          : ['fas', 'circle']}}),_c('div',{staticClass:"common-icon__icon-selection__white-space"})],1):(_vm.iconData && _vm.iconData.svgPathData)?_c('svg',{class:['svg-inline--fa ', 'fa-' + _vm.iconData.iconName],attrs:{"aria-hidden":"true","focusable":"false","data-prefix":_vm.iconData.prefix,"data-icon":_vm.iconData.iconName,"role":"img","viewBox":'0 0 ' + _vm.iconData.width + ' ' + _vm.iconData.height}},[_c('path',{attrs:{"fill":"currentColor","d":_vm.iconData.svgPathData}})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }