import Vue from "vue";
import * as lodash from "lodash";
import { BackgroundTypes } from "@/enums";
import backgroundOptionsJson from "../../public/json/backgrounds.json";
const backgroundOptions = backgroundOptionsJson.map((background) => {
    return { value: background, text: background };
});
export default Vue.extend({
    data() {
        return {
            backgroundOptions: [],
            templates: {
                default2022: {
                    params: {
                        showSearchbar: { "type": "checkbox", hidden: false, defaultValue: true },
                    }
                },
                default2022NoSearchBar: {
                    params: {}
                },
                hallways: {
                    params: {
                        backgroundImg: { "type": "file", hidden: false },
                        showSearchbar: { "type": "checkbox", hidden: false, defaultValue: true },
                    }
                },
                headerSearch: {
                    params: {
                        themeColor: { "type": "color", hidden: false },
                        backgroundType: {
                            "type": "select", options: [
                                //{value: '', text: '---Seleziona un tipo di sfondo---'},
                                { value: BackgroundTypes.IMG, text: 'Immagine', pilots: ["backgroundImg"] },
                                { value: BackgroundTypes.COLOR, text: 'Colore', pilots: ["backgroundColor"] },
                                { value: BackgroundTypes.GRADIENT, text: 'Gradiente', pilots: ["backgroundGradient"] },
                                { value: BackgroundTypes.NONE, text: 'Trasparente' }
                            ]
                        },
                        backgroundImg: { "type": "select", hidden: true, options: backgroundOptions, pilotedBy: ["backgroundType"] },
                        backgroundColor: { "type": "color", hidden: true, pilotedBy: ["backgroundType"] },
                        backgroundGradient: { "type": "gradient", hidden: true, pilotedBy: ["backgroundType"] },
                    }
                }
            },
            defaultFields: {
                answer_css: { "type": "css" }
            }
        };
    },
    computed: {},
    mounted() {
        backgroundOptionsJson.forEach((background) => {
            const param = { value: background, text: background };
            this.backgroundOptions.push(param);
        });
    },
    methods: {
        getTemplatesList() {
            return this.templates;
        },
        getDefaultTemplateParamsList() {
            return this.defaultFields;
        },
        getTemplateParamsList(templateName) {
            lodash.merge(this.templates[templateName].params, this.defaultFields);
        }
    }
});
