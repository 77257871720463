import Vue from "vue";
import { ModalManagementMode } from "@/enums";
import { localizationService, webRestService } from "@/main";
import { ModalForm, ModalFormParams, ModalFormRedirect, ModalFormRouting } from "@/components/ModalTypes";
export default Vue.extend({
    watch: {
        responseForm: {
            handler() {
                this.responseFormFiltered = this.lodash.cloneDeep(this.responseForm);
                this.applyFilter();
            },
            deep: true
        },
    },
    computed: {
        tagFormAuths() {
            return (codeTag) => {
                return this.tagForms
                    .filter((tagForm) => tagForm.value === codeTag)
                    .flatMap(tagForm => tagForm.form_params.auth_type);
            };
        }
    },
    props: {
        responseForm: {
            type: Array,
        },
        tagForms: {
            type: Array,
        },
    },
    components: {
        ModalFormRouting,
        ModalForm,
        ModalFormParams,
        ModalFormRedirect,
    },
    data() {
        return {
            formFilterModel: {},
            debounceTimeout: {},
            ModalManagementMode,
            modalManagementMode: "",
            responseFormFiltered: [],
            tagFormsList: [],
            formSetting: {},
            localizationService,
        };
    },
    mounted() {
        this.$emit("setFormTitle", this.localizationService.DashLoc('impostazioni_form'));
        this.formFilterModel = {
            code_tag: "",
            description: "",
            code_form: "",
            auth_type: ""
        };
        this.responseFormFiltered = this.lodash.cloneDeep(this.responseForm);
    },
    methods: {
        theFilter(item) {
            let toReturn = true;
            const vueinstance = this;
            this.lodash.forEach(this.formFilterModel, function (f, k) {
                if (vueinstance.lodash.has(item, k)) {
                    const key = k;
                    toReturn = toReturn && (f.trim() == "" || item[key].toLowerCase().includes(vueinstance.formFilterModel[key].toLowerCase()));
                }
                else if (String(k) === 'auth_type') {
                    const authType = vueinstance.formFilterModel.auth_type.toLowerCase();
                    const authTypes = vueinstance.tagFormAuths(item.code_tag);
                    toReturn = toReturn && (authTypes.length === 0 || authTypes.some((auth => auth?.toLowerCase().includes(authType.trim()))));
                }
            });
            return toReturn;
        },
        startDebounce() {
            if (this.debounceTimeout) {
                clearTimeout(this.debounceTimeout);
            }
            this.debounceTimeout = setTimeout(() => {
                this.applyFilter();
            }, 500); // delay
        },
        applyFilter() {
            this.responseFormFiltered = this.lodash.filter(this.responseForm, this.theFilter);
        },
        tagExists(codice) {
            let ilTag = this.lodash.find(this.tagForms, (t) => t.value == codice);
            return !!ilTag;
        },
        tagColor(codice) {
            let ilTag = this.lodash.find(this.tagForms, (t) => t.value == codice);
            return ilTag ? ilTag.class : "light";
        },
        openModal(res, mode) {
            const authTypes = this.tagFormAuths(res.code_tag);
            res.survey_no_recompile = false;
            if (authTypes.includes("SURVEY")) {
                res.survey_no_recompile = true;
            }
            this.modalManagementMode = mode;
            this.tagFormsList = this.tagForms;
            this.$nextTick(() => {
                this.$refs.showModal.openModal(res);
            });
        },
        formSettingByModal(formSetting, mode) {
            switch (mode) {
                case ModalManagementMode.EDIT:
                    this.$emit("editFormSettings", formSetting);
                    break;
                case ModalManagementMode.ADD:
                case ModalManagementMode.DUPLICATE:
                    this.$emit("addFormSettings", formSetting);
                    break;
                case ModalManagementMode.DELETE:
                    this.$emit("deleteFormSettings", formSetting);
                    break;
            }
            return;
        },
        resizeString(str) {
            if (str?.length > 45) {
                return str.substring(0, 45) + "...";
            }
            return str;
        },
        async openWebsite(res) {
            const authenticationTypes = this.tagFormAuths(res.code_tag);
            const vueInstance = this;
            if (authenticationTypes.every((auth => vueInstance.$refs.showModalRedirect.freeAuthTypes.includes(auth)))) {
                if (authenticationTypes.length === 1) {
                    const url = res.client_url + res.code_form;
                    window.open(url);
                    return;
                }
            }
            const theTagForm = this.lodash.find(this.tagForms, (tagForm) => tagForm.value === res.code_tag);
            const mappingSetting = await webRestService.getMappingSettings(res.id);
            const account = await webRestService.getLoggedAccount();
            vueInstance.$refs.showModalRedirect.openModal(res, mappingSetting, theTagForm, account);
        },
        openRoutingModal(res) {
            webRestService
                .getMappingSettings(res.id)
                .then((response) => {
                this.$refs.showModalRouting.openModal(res, response);
            });
        },
        openParamsModal(res) {
            this.$nextTick(() => {
                const theTagForm = this.lodash.find(this.tagForms, (tagForm) => tagForm.value === res.code_tag);
                this.$refs.showModalParams.openModal(theTagForm.form_params);
            });
        },
        deleteMapping(form, mappingId) {
            webRestService.deleteMappingSetting(form.id, mappingId).then(() => {
                this.openRoutingModal(form);
            });
            return;
        },
        addMapping(form, mapping) {
            webRestService.addMappingSetting(form.id, mapping).then(() => {
                this.openRoutingModal(form);
            });
            return;
        },
    },
});
