import Vue from 'vue';
export default Vue.extend({
    props: {
        userInfo: {
            type: Object,
            required: true
        },
        chatData: {
            type: Object,
            required: true
        }
    },
    mounted() {
        if (this.chatData?.chatId) {
            window.CRISP_WEBSITE_ID = this.chatData?.chatId;
            (function () {
                var d = document;
                var s = d.createElement('script');
                s.src = 'https://client.crisp.chat/l.js';
                s.async = true;
                d.getElementsByTagName('head')[0].appendChild(s);
            })();
            const vm = this;
            window.CRISP_READY_TRIGGER = () => {
                const fondoName = vm.chatData.fondo.toUpperCase();
                const claimFondoName = `CLAIM_${fondoName}`;
                window.$crisp?.push(["config", "show:operator:count", [false]]);
                window.$crisp?.push(["safe", true]);
                window.$crisp?.push(["config", "sound:mute", [false]]);
                const isIscrittoMobile = vm.userInfo.codice_canale === 'ISCRITTO_MOBILE';
                setTimeout(() => {
                    const isLiveChatEnabled = window.$crisp.is('website:available');
                    const showOrHideBasedOnAvailability = isLiveChatEnabled ? 'show' : 'hide';
                    window.$crisp?.push(['do', `chat:${showOrHideBasedOnAvailability}`]);
                }, 1500);
                if (vm.userInfo?.email) {
                    window.$crisp?.push(['set', 'user:email', vm.userInfo.email]);
                }
                if (vm.userInfo?.telefono) {
                    window.$crisp?.push(['set', 'user:phone', vm.userInfo.telefono]);
                }
                if (vm.userInfo?.nome || vm.userInfo?.cognome) {
                    window.$crisp?.push(['set', 'user:nickname', ((vm.userInfo?.nome || '') + ' ' + (vm.userInfo?.cognome || '')).trim()]);
                    window.$crisp?.push(["set", "session:data", ["nome", vm.userInfo?.nome]]);
                    window.$crisp?.push(["set", "session:data", ["cognome", vm.userInfo?.cognome]]);
                }
                if (vm.userInfo?.cod_fiscale) {
                    window.$crisp?.push(['set', 'session:data', ['codFiscale', vm.userInfo.cod_fiscale]]);
                    window.$crisp?.push(["set", "session:segments", [[vm.userInfo.cod_fiscale]]]);
                    window.$crisp?.push(["set", "session:data", ["PIC2", "https://pic.intranet.previmedical.it/pic-callcenter/index.html#/openPratiche?_codFondo=CLAIM_" + claimFondoName + "&_codFiscale=" + vm.userInfo?.cod_fiscale]]);
                }
                const provenienza = "Smartforms-" + (isIscrittoMobile ? 'CITRUS' : 'ARENA');
                window.$crisp?.push(["set", "session:data", ["provenienza", provenienza]]);
                window.$crisp?.push(['set', 'session:data', ['fondo', fondoName]]);
                window.$crisp?.push(["set", "session:segments", [[provenienza, fondoName]]]);
                window.$crisp?.push(["on", "message:sent", removeOtherChannel]);
                window.$crisp?.push(["on", "message:received", removeOtherChannel]);
                styleLiveChat();
            };
        }
    }
});
function removeOtherChannel() {
    const otherChannel = document.querySelector('#crisp-chatbox > div > div > div > div > div > span > a[role="button"]');
    if (otherChannel) {
        otherChannel.id = 'd-none';
    }
}
function styleLiveChat() {
    const crispChatboxOperatorsBoxInterval = setInterval(function () {
        const crispChatboxOperatorsBox = document.querySelector('#crisp-chatbox > div > div > div > div > div > span > span');
        const logoRef = document.querySelector('#crisp-chatbox [target="_blank"]');
        if (logoRef) {
            logoRef.id = 'd-none';
        }
        removeOtherChannel();
        if (crispChatboxOperatorsBox) {
            crispChatboxOperatorsBox.id = 'd-none';
            clearInterval(crispChatboxOperatorsBoxInterval);
        }
    }, 50);
}
