var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
    title: _vm.showTooltip
      ? _vm.isDownload
        ? _vm.$t('confirmation.action.downloadDocument')
        : _vm.tooltipText
        ? _vm.tooltipText
        : ''
      : '',
    variant: _vm.tooltipVariant,
    placement: _vm.tooltipPlacement,
  }),expression:"{\n    title: showTooltip\n      ? isDownload\n        ? $t('confirmation.action.downloadDocument')\n        : tooltipText\n        ? tooltipText\n        : ''\n      : '',\n    variant: tooltipVariant,\n    placement: tooltipPlacement,\n  }"}],staticClass:"common-button",class:[
    ("custom-button--" + _vm.typeButton + " btn-" + _vm.size),
    _vm.classButton,
    'animate-on-hover-' + _vm.animateOnHoverDirection,
    { 'animate-on-hover': _vm.animateOnHover },
    { 'is-title': _vm.isTitle },
    { 'is-timeline': _vm.isTimeline },
    { 'is-next': _vm.isNext },
    { 'is-cancel': _vm.isCancel },
    { 'is-confirm': _vm.isConfirm },
    { 'is-download': _vm.isDownload },
    { 'is-collapse': _vm.isCollapse },
    { 'btn-action': _vm.isAction },
    { 'show-collapse': _vm.showCollapse },
    { legacy: _vm.legacy },
    { outline: _vm.outline },
    { icon: _vm.icon },
    { open: _vm.open } ],attrs:{"target":_vm.target && _vm.href ? _vm.target : null,"aria-label":_vm.text ? _vm.text : undefined,"title":_vm.title,"variant":(_vm.outline ? 'outline-' : '') + _vm.variant,"disabled":_vm.disabled,"type":_vm.buttonType},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('clickEvent')}}},[(
      _vm.isPrev ||
      _vm.isNext ||
      _vm.isAdd ||
      _vm.isCancel ||
      _vm.isConfirm ||
      _vm.isDownload ||
      _vm.isDownloadExcel ||
      _vm.isCollapse
    )?_c('div',{staticClass:"common-button__icon d-flex",class:_vm.iconClass},[(_vm.isPrev || _vm.isNext)?_c('font-awesome-icon',{class:{ 'rotate-180': _vm.isNext },attrs:{"icon":"fa-solid fa-arrow-left"}}):_vm._e(),(_vm.isAdd)?_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-plus"}}):_vm._e(),(_vm.isCancel)?_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-trash-alt"}}):_vm._e(),(_vm.isConfirm)?_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-check-circle"}}):_vm._e(),(_vm.isDownload)?_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-arrow-down"}}):_vm._e(),(_vm.isCollapse)?_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-chevron-down"}}):_vm._e(),(_vm.isDownloadExcel)?_c('img',{attrs:{"src":"img/calendar-report/excel.png"}}):_vm._e()],1):_vm._e(),_vm._t("before-label"),(_vm.text)?_c('span',{staticClass:"common-button__text",class:{ 'd-none': !_vm.showLabel }},[_vm._v(_vm._s(_vm.text))]):_vm._e(),_vm._t("after-label")],2)}
var staticRenderFns = []

export { render, staticRenderFns }