import Vue from "vue";
import CommonButton from "@/components/commons/CommonButton.vue";
import CommonLabel from "@/components/commons/CommonLabel.vue";
import CommonVarchar from "@/components/commons/CommonVarchar.vue";
import { localizationService } from "@/main";
export default Vue.extend({
    components: {
        CommonButton,
        CommonLabel,
        CommonVarchar,
    },
    data() {
        return {
            passwordRecoveryFlow: {
                show: false,
                stepIndex: 0,
                username: "",
                password: {
                    new: "",
                    newConfirmed: "",
                    requirements: {
                        minLength: 8,
                    },
                },
            },
            hasChangedPassword: false,
            localizationService,
        };
    },
    computed: {
        passwordMinLengthRequirement() {
            if (!this.passwordRecoveryFlow ||
                !this.passwordRecoveryFlow.password ||
                !this.passwordRecoveryFlow.password.new)
                return false;
            return (this.passwordRecoveryFlow.password.new?.length >=
                this.passwordRecoveryFlow.password.requirements.minLength);
        },
        passwordContainsLowerCaseAndUpperCaseRequirement() {
            const lowercaseRegex = /[a-z]/;
            const uppercaseRegex = /[A-Z]/;
            const containsLowercase = lowercaseRegex.test(this.passwordRecoveryFlow.password.new);
            const containsUppercase = uppercaseRegex.test(this.passwordRecoveryFlow.password.new);
            return containsLowercase && containsUppercase;
        },
        passwordContainsAtLeastOneNumberRequirement() {
            const numberRegex = /\d/;
            return numberRegex.test(this.passwordRecoveryFlow.password.new);
        },
        passwordContainsSpecialCharacterRequirement() {
            const specialCharRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
            return specialCharRegex.test(this.passwordRecoveryFlow.password.new);
        },
        passwordsAreTheSameRequirement() {
            return (this.passwordRecoveryFlow.password.new !== null &&
                this.passwordRecoveryFlow.password.new !== "" &&
                this.passwordRecoveryFlow.password.newConfirmed !== null &&
                this.passwordRecoveryFlow.password.newConfirmed !== "" &&
                this.passwordRecoveryFlow.password.new ===
                    this.passwordRecoveryFlow.password.newConfirmed);
        },
        areNewPasswordRequirementsPassed() {
            return (this.passwordMinLengthRequirement &&
                this.passwordContainsLowerCaseAndUpperCaseRequirement &&
                this.passwordContainsAtLeastOneNumberRequirement &&
                this.passwordContainsSpecialCharacterRequirement &&
                this.passwordsAreTheSameRequirement);
        },
    },
    methods: {
        resetPasswordForm() {
            this.passwordRecoveryFlow.show = false;
            this.passwordRecoveryFlow.stepIndex = 0;
            this.passwordRecoveryFlow.password.username = "";
            this.passwordRecoveryFlow.password.new = "";
            this.passwordRecoveryFlow.password.newConfirmed = "";
        },
        handlePasswordChange(event) {
            event.preventDefault();
            event.stopPropagation();
            this.hasChangedPassword = true;
            this.resetPasswordForm();
            setTimeout(() => {
                this.hasChangedPassword = false;
            }, 3000);
            this.$emit("submit");
        },
        handleExit() {
            this.$emit("exit");
        },
    },
});
