import Vue from "vue";
import VueCookies from "vue-cookies";
import CommonAlert from "@/components/commons/CommonAlert.vue";
import CommonButton from "@/components/commons/CommonButton.vue";
import CommonModal from "@/components/commons/CommonModal.vue";
import { localizationService } from "@/main";
Vue.use(require("vue-cookies"));
Vue.use(VueCookies);
export default Vue.extend({
    components: {
        CommonAlert,
        CommonButton,
        CommonModal,
    },
    props: {
        field: {
            type: Object,
            default: () => {
                return {
                    title: "",
                    value: "",
                };
            },
        },
        customerCode: {
            type: String,
            default: "",
        },
        isBtn: {
            type: Boolean,
            default: true,
        },
        okBtnVariant: {
            type: String,
            default: "success",
        },
        positionStatic: {
            type: Boolean,
            default: false,
        },
        templateName: String
    },
    data() {
        return {
            localizationService,
            disabledButton: true,
            showModal: false,
            alreadyAccepted: false,
            proceed: "",
            footerDescription: "",
        };
    },
    mounted() {
        this.verifyCookie();
        this.proceed = this.localizationService.CommonLoc('accetto');
        this.footerDescription = this.localizationService.CommonLoc('scorri_termini');
    },
    methods: {
        openModal() {
            this.showModal = true;
            this.verifyCookie();
        },
        verifyCookie() {
            if (Vue.$cookies.get("smartform-tc-" + this.customerCode)) {
                this.alreadyAccepted = true;
                this.proceed = this.localizationService.CommonLoc('chiudi');
                this.disabledButton = false;
                this.footerDescription = this.localizationService.CommonLoc('termini_accettati');
            }
            else {
                this.showModal = !this.showModal;
            }
        },
        onModalSubmit() {
            Vue.$cookies.set("smartform-tc-" + this.customerCode, true, "1M");
        },
        scrollFunction(el) {
            if ((el.target.offsetHeight + el.target.scrollTop) >= el.target.scrollHeight - el.target.scrollHeight / 8) {
                this.disabledButton = false;
            }
        },
        handleOpenModal() {
            if (!this.alreadyAccepted) {
                this.disabledButton = this.$refs.modalContent.scrollHeight > this.$refs.modalContent.clientHeight;
            }
        }
    }
});
