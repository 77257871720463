import Vue from "vue";
import CommonVarchar from "./CommonVarchar.vue";
export default Vue.extend({
    components: {
        CommonVarchar,
    },
    props: {
        autocomplete: {
            type: String,
            default: undefined,
        },
        id: {
            type: String,
            default: undefined,
        },
        fieldType: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: undefined,
        },
        readonly: {
            type: Boolean,
            default: undefined,
        },
        required: {
            type: Boolean,
            default: undefined,
        },
        state: {
            type: Boolean,
            default: undefined,
        },
        name: {
            type: String,
            default: undefined,
        },
        placeholder: {
            type: String,
            default: undefined,
        },
        description: {
            type: String,
            default: undefined,
        },
        appendValue: {
            type: String,
            default: undefined,
        },
        minLength: {
            type: String,
            default: undefined,
        },
        maxLength: {
            type: String,
            default: undefined,
        },
        validationErrors: {
            type: String,
            default: undefined,
        },
    },
    methods: {
        handleInput(event) {
            this.$emit("input", event);
        },
    },
});
