import Vue from "vue";
import CommonButton from "@/components/commons/CommonButton.vue";
import CommonCard from "@/components/commons/CommonCard.vue";
export default Vue.extend({
    components: {
        CommonButton,
        CommonCard,
    },
    props: {
        name: {
            type: String,
            default: "",
        },
        size: {
            type: String,
            default: "",
        },
        fileType: {
            type: String,
            default: "default",
        },
    },
    computed: {
        fileIcon() {
            let iconName = "", color = ""; //Color is optional
            switch (this.fileType) {
                case "application/pdf":
                    iconName = "fas fa-file-pdf";
                    color = "#ce0525";
                    break;
                case "image/png":
                case "image/jpg":
                case "image/jpeg":
                    iconName = "fas fa-file-image";
                    color = "#2e42e0";
                    break;
                case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                case "application/msword":
                    iconName = "fas fa-file-word";
                    color = "#1B5EBE";
                    break;
                case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                case "application/vnd.ms-excel":
                    iconName = "fas fa-file-excel";
                    color = "#03ae30";
                    break;
                case "csv":
                    iconName = "fas fa-file-csv";
                    break;
                //Other file types here...
                default:
                    iconName = "fas fa-file";
                    break;
            }
            return {
                icon: iconName,
                label: this.fileType,
                color: color,
            };
        },
    },
    methods: {
        handleRemove() {
            this.$emit("remove");
        },
    },
});
