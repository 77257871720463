import Vue from "vue";
import { SSO_KEY } from "@/constants";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
library.add(faSignOut);
export default Vue.extend({
    props: {
        email: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        noCaret: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        handleLogout() {
            let cookieName = `${SSO_KEY.COOKIE_OTP_CODE}_${this.$route.params.customerCode}`;
            this.$cookies.remove(cookieName);
            location.reload();
        },
    },
});
