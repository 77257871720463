import Vue from "vue";
//WIP: Dynamic import for icon
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faCheckCircle, faCheckSquare, faCircle, faSquare, } from "@fortawesome/free-solid-svg-icons";
library.add(faUser, faCircle, faCheckCircle, faSquare, faCheckSquare);
export default Vue.extend({
    data() {
        return {
            iconData: null,
            loadedIcons: [],
        };
    },
    props: {
        icon: {
            type: Object,
            default: () => ({
                icon: "far fa-star",
                label: "",
            }),
        },
        flat: {
            type: Boolean,
            default: false,
        },
        active: {
            type: Boolean,
            default: false,
        },
        inAlert: {
            type: Boolean,
            default: false,
        },
        isSelection: {
            type: Boolean,
            default: false,
        },
        isSelected: {
            type: Boolean,
            default: false,
        },
        multiSelection: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        fontAwesomeVariant() {
            if (!this.icon) {
                return "";
            }
            let currentFaIconVariant = "";
            const faIconVariant = this.icon.icon.split(" ")[0];
            switch (faIconVariant) {
                case "fas":
                    currentFaIconVariant = "solid";
                    break;
                case "far":
                    currentFaIconVariant = "regular";
                    break;
            }
            return currentFaIconVariant;
        },
        fontAwesomeIcon() {
            if (!this.icon) {
                return "";
            }
            const faIcon = this.icon?.icon?.split(" ")[1];
            return this.toCamelCase(faIcon);
        },
        iconStyle() {
            if (!this.icon || !this.icon.color) {
                return undefined;
            }
            return {
                color: this.icon.color,
            };
        },
    },
    methods: {
        toCamelCase(input) {
            // Rimuove spazi e trattini e converte in camelCase
            return input?.replace(/-./g, (x) => x[1].toUpperCase());
        },
        async loadIcon() {
            if (!this.fontAwesomeIcon ||
                this.loadedIcons.includes(this.fontAwesomeIcon)) {
                return "";
            }
            //https://webpack.js.org/api/module-methods/
            const iconData = await import(
            /* webpackChunkName: "fontawesome-icons" */
            /* webpackMode: "eager" */
            `@fortawesome/free-${this.fontAwesomeVariant}-svg-icons/${this.fontAwesomeIcon}`);
            return iconData;
        },
    },
    async mounted() {
        await this.loadIcon().then((icon) => {
            this.iconData = icon;
            this.loadedIcons.push(this.fontAwesomeIcon);
        });
    },
});
