import Vue from "vue";
import mantainanceMixin from "@/mixins/mantainanceMixin";
import leftPanel from './components/LeftPanel.vue';
export default Vue.extend({
    mixins: [mantainanceMixin],
    props: {
        templateConfig: {}
    },
    components: {
        leftPanel
    }
});
