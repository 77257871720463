import Vue from "vue";
import { globalEventBus, localizationService, webRestService } from "@/main";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import "@/assets/css/smartform.css";
import "@/assets/scss/smartform.scss";
import "vue-multiselect/dist/vue-multiselect.min.css";
import 'bootstrap/scss/bootstrap.scss';
import { TagTypeEnum } from "@/enums";
import swal from "sweetalert2";
import { SSO_KEY } from "@/constants";
import { getLogosHTML, handleBackground } from "@/utils/util";
Vue.use(VueSweetalert2);
export default Vue.extend({
    data() {
        return {
            webRestService,
            localizationService,
            languages: [],
            languageForm: {},
            languagesDescription: [],
            hasLocale: false,
            currentLanguage: '',
            htmlToInject: "",
            showLogos: false,
        };
    },
    computed: {
        customerCode() {
            return this.$route.params.customerCode;
        },
        verifyLanguageCookie() {
            return Vue.$cookies.get("smartform-language-" + this.customerCode);
        },
        browserLanguage() {
            return navigator.language;
        },
        otpCode() {
            if (this.$store.getters[SSO_KEY.GET_STORE_OTP_CODE]) {
                return this.$store.getters[SSO_KEY.GET_STORE_OTP_CODE];
            }
            else {
                let cookieName = `${SSO_KEY.COOKIE_OTP_CODE}_${this.customerCode}`;
                let otp = this.$cookies.get(cookieName);
                if (otp) {
                    this.$store.dispatch(SSO_KEY.SET_STORE_OTP_CODE, atob(otp));
                }
                return this.$store.getters[SSO_KEY.GET_STORE_OTP_CODE];
            }
        },
    },
    mounted() {
        this.getLanguages();
        this.getLogos();
        globalEventBus.$on('change-locale', (language) => {
            this.setLanguage(language, true);
        });
    },
    methods: {
        getLogos() {
            webRestService.getCustomerConfig(this.customerCode).then((response) => {
                this.htmlToInject = getLogosHTML(response);
                this.showLogos = !!this.htmlToInject;
                handleBackground(response);
            });
        },
        getLanguages() {
            webRestService.getSiteMap(this.customerCode).then((response) => {
                if (this.hasLanguage(response)) {
                    let languagesDescription = require('@/i18n/languages_description.json');
                    this.languages.forEach((language) => {
                        if (Object.keys(languagesDescription).includes(language)) {
                            this.languagesDescription.push(languagesDescription[language]);
                        }
                    });
                    switch (true) {
                        case this.verifyLanguageCookie != null && this.languages.includes(this.verifyLanguageCookie):
                            this.setLanguage(this.verifyLanguageCookie, false);
                            break;
                        case this.languages.includes(this.browserLanguage.split('-')[0]):
                            this.setLanguage(this.browserLanguage.split('-')[0], false);
                            break;
                        case this.languages.length > 0 && this.languages.length == 1:
                            this.setLanguage(this.languages[0], false);
                            break;
                    }
                }
            });
        },
        setLanguage(language, reload) {
            Vue.$cookies.set("smartform-language-" + this.customerCode, language, "1d");
            if (reload) {
                this.$router.go(0);
            }
            this.hasLocale = true;
            this.currentLanguage = language;
            this.$i18n.locale = language;
        },
        hasLanguage(input) {
            let hasLanguage = false;
            this.languages = [];
            this.languageForm = input;
            input.children.forEach((child) => {
                if (TagTypeEnum.FORM_LANG === child.tag_type) {
                    hasLanguage = true;
                    child.params.forEach((params) => {
                        if (params.params_type === "FORM_LANG") {
                            this.languages.push(params.lang_code);
                        }
                    });
                }
            });
            if (hasLanguage) {
                if (this.languages.length !== input.children.length) {
                    console.log("Configurazione form non valida (tag lingua assenti)");
                    swal.fire({
                        text: this.localizationService.CommonLoc('errore_recupero_dati'),
                        icon: 'error',
                        confirmButtonText: this.localizationService.CommonLoc('ricarica_form')
                    }).then(() => {
                        // window.location.reload();
                    });
                }
            }
            return hasLanguage;
        },
    }
});
