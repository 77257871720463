import Vue from "vue";
import LangIcon from "@/components/LangIcon.vue";
export default Vue.extend({
    components: {
        LangIcon
    },
    props: {
        customerLogo: {
            type: String,
            default: ""
        },
        showCustomerLogo: {
            type: Boolean,
            default: true
        },
        languagesDescription: {
            type: Array,
            default: []
        },
        languages: {
            type: Array,
            default: []
        }
    },
    methods: {
        setLanguage: function setLanguage(lang) {
            this.$emit("setLanguage", lang);
        }
    },
    computed: {},
});
