const getters = {
    getAuthToken(state) {
        return state.authToken;
    },
    getOtpCode(state) {
        return state.otpCode;
    },
    getNextRoute(state) {
        return state.nextRoute;
    },
    getIsOtpCodeToSend(state) {
        return state.isOtpCodeToSend;
    }
};
export default getters;
