import Vue from 'vue';
import Vuex from 'vuex';
import auth from '@/store/auth';
import chat from '@/store/chat';
import footer from '@/store/footer';
Vue.use(Vuex);
const store = {
    state: {},
    modules: { auth, chat, footer },
};
export default new Vuex.Store(store);
