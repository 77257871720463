import Vue from "vue";
import { globalEventBus } from "@/main";
import LangIcon from "@/components/LangIcon.vue";
export default Vue.extend({
    components: { LangIcon },
    props: {
        languages: Array,
        selectLanguage: String,
        currentLanguage: String
    },
    data() {
        return {};
    },
    mounted() {
        if (this.selectLanguage && this.selectLanguage != "") {
            this.$i18n.locale = this.selectLanguage;
        }
    },
    methods: {
        setLanguage: function (lang) {
            if (this.$i18n.locale !== lang) {
                this.$i18n.locale = lang;
                globalEventBus.$emit('change-locale', lang);
            }
        }
    }
});
