import Vue from "vue";
import CommonCard from "@/components/commons/CommonCard.vue";
import CommonIcon from "@/components/commons/CommonIcon.vue";
export default Vue.extend({
    components: {
        CommonCard,
        CommonIcon,
    },
    props: {
        title: {
            type: String,
            default: "",
        },
        subtitle: {
            type: String,
            default: "",
        },
        icon: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
});
