import Vue from "vue";
export default Vue.extend({
    methods: {
        setLanguage: function setLanguage() {
            this.$emit("setLanguage", this.lang);
        }
    },
    props: {
        lang: {
            type: String
        },
        component: {
            type: String,
            default: "multiselect"
        }
    }
});
