import { ModalManagementMode } from "@/enums";
import Vue from "vue";
import { localizationService } from "@/main";
export default Vue.extend({
    props: {
        showModal: { type: Boolean },
        mode: {
            type: String,
        },
    },
    data() {
        return {
            ModalManagementMode,
            internalShowModal: false,
            systemSetting: {},
            localizationService,
            code: "",
            value: "",
            okButton: "",
            okButtonColor: "primary",
        };
    },
    mounted() {
        this.okButton = this.localizationService.DashLoc('salva');
    },
    computed: {
        validationGlobalState() {
            if (this.code != "" && this.value != "") {
                return false;
            }
            else {
                return true;
            }
        },
        isCodeDisabled() {
            switch (this.mode) {
                case ModalManagementMode.EDIT:
                case ModalManagementMode.DELETE: {
                    return true;
                }
                case ModalManagementMode.DUPLICATE:
                case ModalManagementMode.ADD: {
                    return false;
                }
            }
        },
        partialTitle() {
            switch (this.mode) {
                case ModalManagementMode.ADD:
                    return this.localizationService.DashLoc('aggiungi').toString();
                case ModalManagementMode.EDIT:
                    return this.localizationService.DashLoc('modifica').toString();
                case ModalManagementMode.DELETE:
                    return this.localizationService.DashLoc('elimina').toString();
                case ModalManagementMode.DUPLICATE:
                    return this.localizationService.DashLoc('Duplica').toString();
            }
        },
    },
    methods: {
        openModal(res) {
            this.code = res.code;
            this.value = res.value;
            this.internalShowModal = true;
            this.systemSetting = res;
            if (this.mode === ModalManagementMode.ADD) {
                this.freeComponents();
                this.okButton = this.localizationService.DashLoc('salva').toString();
                this.okButtonColor = "primary";
            }
            else if (this.mode === ModalManagementMode.DELETE) {
                this.okButton = this.localizationService.DashLoc('elimina').toString();
                this.okButtonColor = "danger";
            }
            else {
                this.okButton = this.localizationService.DashLoc('salva').toString();
                this.okButtonColor = "primary";
            }
        },
        handleOk(code, value) {
            this.systemSetting = { code: code, value: value };
            this.$emit("systemSettingByModal", this.systemSetting, this.mode);
            this.freeComponents();
        },
        freeComponents() {
            this.code = "";
            this.value = "";
        },
    },
});
