import Vue from "vue";
import { localizationService, webRestService } from "@/main";
import Paginator from "@/components/Paginator.vue";
import SearchBar from "@/components/commons/SearchBar.vue";
import CommonButton from "@/components/commons/CommonButton.vue";
export default Vue.extend({
    components: {
        CommonButton,
        Paginator,
        SearchBar
    },
    data() {
        return {
            whereSearch: false,
            showSearchBox: true,
            resultsSet: [],
            resultsFitered: [],
            timer: 0,
            searchIcon: "",
            notFoundIcon: "",
            searchModel: "",
            selectedResult: undefined,
            selectedTag: undefined,
            localizationService,
            webRestService,
        };
    },
    props: {
        customerCode: String,
        rootTag: String,
        currentTag: String,
        otpCode: String,
        selectedLanguage: String,
        templateName: String,
    },
    mounted() {
        this.searchIcon = require("@/assets/searchform.svg");
        this.notFoundIcon = require("@/assets/form-not-found.svg");
    },
    methods: {
        filter(text, length, clamp) {
            clamp = clamp || '...';
            var node = document.createElement('div');
            node.innerHTML = text;
            var content = node.textContent;
            return content.length > length ? content.slice(0, length) + clamp : content;
        },
        handleResultsSetFiltered(resultsFiltered) {
            this.lodash.forEach(resultsFiltered, (rf) => this.lodash.map(rf.breadcrumb, (item) => item.id = this.lodash.uniqueId()));
            this.resultsFitered = resultsFiltered;
        },
        debounce(func, timeout = 1000) {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                func();
            }, timeout);
        },
        onSearchChange() {
            if (this.searchModel.trim().length == 1) {
                this.searchModel = "🔎" + this.searchModel;
            }
        },
        searchTheForm() {
            if (this.searchModel.length < 3) {
                return;
            }
            let that = this;
            var laRicerca = this.searchModel;
            let startingTag = this.whereSearch ? this.currentTag : this.rootTag;
            webRestService
                .searchInForm(laRicerca, this.customerCode, this.otpCode, startingTag)
                .then((response) => {
                if (that.selectedLanguage.trim() != "") {
                    response = that.lodash.filter(response, (r) => r.lang_code == that.selectedLanguage);
                }
                let queryRegex = new RegExp(laRicerca, "gmi");
                that.showSearchBox = false;
                that.lodash.forEach(response, (r) => {
                    r.truncatedDescriptions = [];
                    that.lodash.forEach(r.descriptions, (d) => {
                        d.description = this.removeTags(d.description);
                        let pos = d.description.toLowerCase().indexOf(laRicerca);
                        let truncated = d.description.substring(pos - 250, pos);
                        truncated = truncated + d.description.substring(pos, pos + 250);
                        let highlighted = "..." +
                            truncated.replace(queryRegex, "<mark class='text-mark text-mark-default'>" + laRicerca + "</mark>") +
                            "...";
                        r.truncatedDescriptions.push({ description: highlighted, id: d.id });
                    });
                });
                that.resultsSet = response.map(res => { return { ...res, id: Math.floor(Math.random() * 101) }; });
            })
                .catch(() => {
                that.resultsSet = [];
            });
        },
        goToResult(result) {
            sessionStorage.setItem("searchData", JSON.stringify({ search: this.searchModel, id: this.selectedResult?.id }));
            this.$emit("resultSelected", result.code_tag);
        },
        removeTags(str) {
            return str.replace(/(<([^>]+)>)/gi, "");
        },
    },
});
