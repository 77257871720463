import Vue from "vue";
import { localizationService, webRestService } from "@/main";
import { getLogosHTML, handleBackground, handleCustomerLogo, handleFavicon } from "@/utils/util";
import { FormFieldTypeCodeEnum, ReadOnlyType, TagTypeEnum } from "@/enums";
export default Vue.extend({
    computed: {
        customerCode() {
            return this.$route.params.customerCode;
        },
        codeTag() {
            return this.$route.params.codeTag;
        }
    },
    data() {
        return {
            localizationService,
            username: "",
            password: "",
            status: 0,
            formCode: "",
            customerLogo: "",
            formTitle: "",
            htmlToInject: "",
            showLogos: false,
            showPass: false,
            hasLocales: false,
            languages: [],
            languagesDescription: [],
            selectedLanguage: "",
            formAction: "",
            formMessage: "",
            formFields: [],
            showLanguageModal: false
        };
    },
    mounted() {
        let favicon = require("@/assets/favicons/smartform.ico");
        webRestService
            .getCustomerConfig(this.customerCode)
            .then((response) => {
            if (response) {
                this.htmlToInject = getLogosHTML(response);
                this.showLogos = !!this.htmlToInject;
                this.formCode = response.form_code;
                this.formTitle = response.form_title;
                favicon = handleFavicon(response);
                this.customerLogo = handleCustomerLogo(response);
                handleBackground(response);
            }
            else {
                this.$router.push(this.customerCode + '/login');
            }
        })
            .finally(() => {
            document.querySelectorAll('[rel="icon"]')[0].href = favicon ? favicon : "";
            document.body.style.backgroundAttachment = "fixed";
            document.body.style.backgroundRepeat = "no-repeat";
            document.body.style.backgroundSize = "cover";
        });
        if (document.location.href.includes("/token-login")) {
            this.getTagChildren();
        }
    },
    methods: {
        handleHasLocale(hasLocale) {
            this.hasLocales = true;
        },
        handleSelectedLanguage(language) {
            this.selectedLanguage = language;
        },
        onLanguagesLoaded(languages, languagesDescription) {
            this.languages = languages;
            this.languagesDescription = languagesDescription;
        },
        setLanguage(language) {
            Vue.$cookies.set("smartform-language-" + this.customerCode, language, "1d");
            this.getTagChildren();
        },
        getTagChildren(lang) {
            webRestService
                .getTagsChildren(lang ?? this.codeTag, this.customerCode)
                .then((response) => {
                if (this.haveLanguage(response)) {
                    const langCookie = Vue.$cookies.get("smartform-language-" + this.customerCode);
                    if (langCookie) {
                        this.showLanguageModal = false;
                        const langCode = response.children.find((child) => child.params.some(param => param.lang_code === langCookie));
                        if (langCode) {
                            this.getTagChildren(langCode.code_tag);
                        }
                    }
                    else {
                        let languagesDescription = require('@/i18n/languages_description.json');
                        this.languages.forEach((language) => {
                            if (Object.keys(languagesDescription).includes(language)) {
                                this.languagesDescription.push(languagesDescription[language]);
                            }
                        });
                        this.showLanguageModal = true;
                    }
                }
                else {
                    let formField = {};
                    formField.form_fields = [];
                    formField.properties = [];
                    if (response.form_fields) {
                        const tokenLogin = response.form_fields.find((field) => field.type === FormFieldTypeCodeEnum.TOKEN_LOGIN);
                        if (tokenLogin) {
                            tokenLogin.value.fields.forEach((field) => {
                                let theField = {};
                                theField.keyID = this.lodash.uniqueId();
                                theField.type = "PROPERTY";
                                theField.value = field.property + "---" + theField.keyID;
                                theField.mapping = "";
                                theField.hidden = false;
                                theField.required = field.required;
                                theField.readonly = ReadOnlyType.EDITABLE;
                                formField.form_fields.push(theField);
                            });
                        }
                    }
                    if (response.properties) {
                        formField.properties = response.properties.filter((property) => formField.form_fields.some((field) => field.value.split("---")[0] === property.property_code));
                    }
                    this.formFields.push(formField);
                }
            });
        },
        login(username, password) {
            webRestService
                .tryLogin(username, password, this.customerCode)
                .then(result => {
                this.formAction = result.url;
                this.formMessage = result.message;
                this.$nextTick().then(() => {
                    this.$refs.ssoForm.submit();
                });
            })
                .finally(() => {
                document.body.style.backgroundAttachment = "fixed";
                document.body.style.backgroundRepeat = "no-repeat";
                document.body.style.backgroundSize = "cover";
            });
        },
        haveLanguage(response) {
            this.languages = [];
            let haveLanguages = false;
            response.children.forEach(child => {
                if (child.tag_type === TagTypeEnum.FORM_LANG) {
                    haveLanguages = true;
                    child.params.forEach(p => {
                        if (p.params_type === "FORM_LANG") {
                            this.languages.push(p.lang_code);
                        }
                    });
                }
            });
            return haveLanguages;
        },
    }
});
