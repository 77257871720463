import Vue from "vue";
import { localizationService } from "@/main";
export default Vue.extend({
    props: {},
    data() {
        return {
            internalShowModal: false,
            showNewMapping: false,
            formMapping: [],
            okButton: "",
            formContext: {},
            toDelete: [],
            newMappingElementClient: "",
            newMappingElementSubClient: undefined,
            newMappingElementSubClient2: undefined,
            newMappingElementSubClient3: undefined,
            localizationService
        };
    },
    mounted() {
        this.okButton = this.localizationService.DashLoc('salva');
    },
    computed: {},
    methods: {
        newMappingElementClientToLower() {
            this.newMappingElementClient = this.newMappingElementClient.toLowerCase();
        },
        openModal(formContext, res) {
            this.internalShowModal = true;
            this.formContext = formContext;
            this.formMapping = res;
        },
        deleteMapping(toDelete) {
            if (toDelete.id) {
                this.showNewMapping = false;
                this.$emit("deleteMapping", this.formContext, toDelete.id);
            }
            else {
                this.formMapping = this.formMapping.filter(obj => obj !== toDelete);
            }
        },
        newMapping() {
            this.newMappingElementClient = "";
            this.newMappingElementSubClient = undefined;
            this.newMappingElementSubClient2 = undefined;
            this.newMappingElementSubClient3 = undefined;
            this.showNewMapping = true;
        },
        addMapping() {
            let newMappingElement = {
                form_code: this.formContext.code_form,
                client: this.newMappingElementClient.toLowerCase(),
                sub_client: this.newMappingElementSubClient,
                sub_client2: this.newMappingElementSubClient2,
                sub_client3: this.newMappingElementSubClient3,
            };
            this.showNewMapping = false;
            this.$emit("addMapping", this.formContext, newMappingElement);
        },
        processString(text) {
            return text ? text : "--";
        }
    },
});
