import Vue from "vue";
import CommonButton from "@/components/commons/CommonButton.vue";
import CommonVarchar from "@/components/commons/CommonVarchar.vue";
import { globalEventBus, localizationService, webRestService } from "@/main";
import PropertyType from "@/components/FieldTypes/PropertyType.vue";
import ChooseLanguage from "@/components/ChooseLanguage.vue";
import loginMixin from "@/mixins/loginMixin";
export default Vue.extend({
    computed: {
        customerCode() {
            return this.$route.params.customerCode;
        },
    },
    mixins: [loginMixin],
    components: {
        ChooseLanguage,
        PropertyType,
        CommonButton,
        CommonVarchar
    },
    data() {
        return {
            localizationService,
            token: "",
            userEmail: "",
            formValues: {},
            formPropertyValidation: {},
        };
    },
    mounted() {
    },
    methods: {
        onPropertyStateChanged(valid, pt) {
            Vue.set(this.formPropertyValidation, pt, valid);
        },
        validateProps() {
            globalEventBus.$emit("validatePlease");
        },
        reinvia() {
        },
        handleSubmit(event) {
            event.preventDefault();
            event.stopPropagation();
            let isValid = true;
            globalEventBus.$emit("validatePlease", true);
            setTimeout(() => {
                this.lodash.forEach(this.formPropertyValidation, function (fpv, key) {
                    if (!fpv) {
                        console.log("Validazione fallita per: " + key);
                    }
                    isValid = isValid && fpv;
                });
                if (isValid) {
                    webRestService.loginWithToken(this.formValues, this.token, this.customerCode).then((result) => {
                        this.formAction = result.url;
                        this.formMessage = result.message;
                        this.$nextTick().then(() => {
                            this.$refs.ssoForm.submit();
                        });
                    }).catch((err) => {
                    });
                    //this.userEmail = 'gi*********vo@rbtech.dev'
                    //this.$bvModal.show("confirm-email-token");
                }
            }, 1000);
        },
        setValueToken(tokenModel) {
            this.token = tokenModel;
        },
        onPropertyIn(arg) {
            this.formValues[arg.code.split('---')[0]] = arg.value;
        }
    }
});
