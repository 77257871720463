import Vue from "vue";
import loginMixin from "@/mixins/loginMixin";
import AppWelcome from "@/components/Welcome/AppWelcome.vue";
import CommonHeader from "@/components/commons/CommonHeader.vue";
import SearchBar from "@/components/commons/SearchBar.vue";
import SmartForms from "@/components/SmartForms.vue";
import CommonButton from "@/components/commons/CommonButton.vue";
import CommonLabel from "@/components/commons/CommonLabel.vue";
import CommonVarchar from "@/components/commons/CommonVarchar.vue";
import FormLogin from "@/components/Forms/FormLogin.vue";
import FormPasswordRecovery from "@/components/Forms/FormPasswordRecovery.vue";
import { webRestService } from "@/main";
import { TagTypeEnum } from "@/enums";
import { SSO_KEY } from "@/constants";
import homeMixin from "@/mixins/homeMixin";
import smartFormHeaderMixin from "@/mixins/smartFormHeaderMixin";
export default Vue.extend({
    mixins: [loginMixin, homeMixin, smartFormHeaderMixin],
    components: {
        AppWelcome,
        CommonVarchar,
        CommonButton,
        CommonLabel,
        SmartForms,
        SearchBar,
        CommonHeader,
        FormLogin,
        FormPasswordRecovery,
    },
    props: {
        templateConfig: {},
    },
    data() {
        return {
            image: "",
            languageTagMap: {},
            passwordRecoveryFlow: {
                show: false,
            },
        };
    },
    methods: {},
    mounted() {
        this.image = require("@/assets/default-bg.png");
    },
    computed: {
        otpCode() {
            if (this.$store.getters[SSO_KEY.GET_STORE_OTP_CODE]) {
                return this.$store.getters[SSO_KEY.GET_STORE_OTP_CODE];
            }
            else {
                let cookieName = `${SSO_KEY.COOKIE_OTP_CODE}_${this.customerCode}`;
                let otp = this.$cookies.get(cookieName);
                if (otp) {
                    this.$store.dispatch(SSO_KEY.SET_STORE_OTP_CODE, atob(otp));
                }
                return this.$store.getters[SSO_KEY.GET_STORE_OTP_CODE];
            }
        },
    },
    watch: {
        formCode(newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }
            webRestService
                .getFormEntryHeader(this.formCode, this.customerCode, this.otpCode)
                .then((response) => {
                this.updateHeaderFields(response.form_fields, true);
                response.children.forEach((children) => {
                    if (children.tag_type === TagTypeEnum.FORM_LANG) {
                        const theParam = children.params.find((param) => {
                            return param.params_type === "FORM_LANG";
                        });
                        if (!!theParam) {
                            this.languageTagMap[theParam.lang_code] = children.code_tag;
                        }
                    }
                });
                const selectedLang = this.selectedLanguage;
                if (!!selectedLang && selectedLang in this.languageTagMap) {
                    webRestService
                        .getFormEntryHeader(this.languageTagMap[selectedLang], this.customerCode, this.otpCode)
                        .then((res) => {
                        this.updateHeaderFields(res.form_fields, false, selectedLang);
                    });
                }
            });
        },
        selectedLanguage(newLang, oldLang) {
            if (newLang === oldLang) {
                return;
            }
            if (newLang in this.languageTagMap) {
                webRestService
                    .getFormEntryHeader(this.languageTagMap[newLang], this.customerCode, this.otpCode)
                    .then((response) => {
                    this.updateHeaderFields(response.form_fields, false, newLang);
                });
            }
        },
    },
});
