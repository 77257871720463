import Vue from "vue";
import CommonLabel from "@/components/commons/CommonLabel.vue";
export default Vue.extend({
    components: {
        CommonLabel,
    },
    data() {
        return {
            modelText: "",
            fieldTypeData: "",
            isUsingPasswordField: false,
        };
    },
    props: {
        modelValue: {
            type: String,
            default: undefined,
        },
        autocomplete: {
            type: String,
            default: "off",
        },
        id: {
            type: String,
            default: "",
        },
        fieldType: {
            type: String,
            default: "",
        },
        debounce: {
            type: [Number, String],
            default: 300,
        },
        label: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        hideLabel: {
            type: Boolean,
            default: false,
        },
        inputPassword: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        showPrepend: {
            type: Boolean,
            default: false,
        },
        state: {
            type: Boolean,
            default: null,
        },
        name: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: "",
        },
        description: {
            type: String,
            default: "",
        },
        appendValue: {
            type: String,
            default: "",
        },
        minLength: {
            type: String,
            default: "0",
        },
        maxLength: {
            type: String,
            default: "",
        },
        validationErrors: {
            type: String,
            default: "",
        },
    },
    computed: {
        showAppend() {
            return (this.appendValue ||
                this.isTel ||
                this.isEmail ||
                this.isUsingPasswordField);
        },
        hideInput() {
            return this.fieldTypeData?.toLowerCase() === "hidden";
        },
        fieldTypeComputed() {
            switch (this.fieldTypeData?.toLowerCase()) {
                //List of all accepted b-form-input types ("hidden" is not allowed)
                case "text":
                case "number":
                case "email":
                case "password":
                case "search":
                case "url":
                case "tel":
                case "date":
                case "time":
                case "range":
                case "color":
                case "hidden":
                    return this.fieldTypeData;
                default:
                    return "text";
            }
        },
        isTel() {
            return this.fieldTypeComputed?.toLowerCase() === "tel";
        },
        isEmail() {
            return this.fieldTypeComputed?.toLowerCase() === "email";
        },
        isPassword() {
            return this.fieldTypeComputed?.toLowerCase() === "password";
        },
        showAppendAsButton() {
            return this.isUsingPasswordField;
        },
    },
    methods: {
        handleInput() {
            this.$emit("input", this.modelText);
        },
        toggleViewPassword() {
            this.isPassword
                ? (this.fieldTypeData = "text")
                : (this.fieldTypeData = "password");
            this.$emit("toogle-view-password");
        },
    },
    watch: {
        modelValue(newValue) {
            this.modelText = newValue;
        },
    },
    mounted() {
        if (this.fieldType) {
            this.fieldTypeData = this.fieldType;
        }
        if (this.fieldType?.toLowerCase() === "password") {
            this.isUsingPasswordField = true;
        }
    },
});
