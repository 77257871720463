var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"common-varchar",class:{ 'is-using-password-field': _vm.isUsingPasswordField }},[(_vm.label && !_vm.hideLabel)?_c('common-label',{staticClass:"mb-2",attrs:{"text":_vm.label,"for":_vm.id,"size":"sm","tag":"label"}}):_vm._e(),_c('b-input-group',{staticClass:"text-input"},[(_vm.showPrepend)?_c('b-input-group-prepend',[_vm._t("prepend")],2):_vm._e(),_c(_vm.hideInput ? 'input' : 'b-form-input',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideInput),expression:"!hideInput"}],tag:"component",staticClass:"input-style-1",class:[
        { 'with-prepend': _vm.showPrepend },
        { 'with-append': _vm.showAppend } ],attrs:{"aria-describedby":"common-input-text-help common-input-text-help-feedback","autocomplete":_vm.autocomplete,"debounce":_vm.hideInput ? 0 : _vm.debounce,"disabled":_vm.disabled,"id":_vm.id,"max":_vm.fieldType === 'number'
          ? _vm.maxLength
            ? _vm.maxLength
            : undefined
          : undefined,"min":_vm.fieldType === 'number'
          ? _vm.minLength
            ? _vm.minLength
            : undefined
          : undefined,"maxlength":_vm.maxLength ? _vm.maxLength : undefined,"name":_vm.name,"placeholder":_vm.placeholder,"readonly":_vm.readonly,"required":_vm.required,"state":_vm.state,"type":_vm.fieldTypeComputed},on:{"update":function($event){return _vm.handleInput()}},model:{value:(_vm.modelText),callback:function ($$v) {_vm.modelText=$$v},expression:"modelText"}}),(_vm.showAppend)?_c('b-input-group-append',{staticClass:"input-style-1-append",class:{ btn: _vm.showAppendAsButton },attrs:{"tag":_vm.showAppendAsButton ? 'button' : 'div',"type":_vm.showAppendAsButton ? 'button' : undefined},on:{"click":function($event){_vm.isUsingPasswordField ? _vm.toggleViewPassword() : ''}}},[(_vm.appendValue)?_c('span',[_vm._v(_vm._s(_vm.appendValue))]):(!_vm.appendValue && (_vm.isTel || _vm.isEmail))?_c('b-icon',{attrs:{"icon":"shield-check","variant":"success"}}):_vm._e(),(_vm.isUsingPasswordField)?_c('div',[(_vm.isPassword)?_c('b-icon',{attrs:{"icon":"eye-fill"}}):_c('b-icon',{attrs:{"icon":"eye-slash-fill"}})],1):_vm._e()],1):_vm._e(),(_vm.description)?_c('b-form-text',{staticClass:"text-input__description w-100",attrs:{"id":"common-input-text-help"}},[(_vm.fieldType !== 'email')?_c('span',[_vm._v(" Descrizione per fieldType email ")]):_c('span',[_vm._v(" Descrizione generica per fieldType email")])]):_vm._e(),(_vm.validationErrors)?_c('b-form-invalid-feedback',{attrs:{"id":"common-input-text-help-feedback"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.validationErrors)}})]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }