export var BackgroundTypes;
(function (BackgroundTypes) {
    BackgroundTypes[BackgroundTypes["IMG"] = 0] = "IMG";
    BackgroundTypes[BackgroundTypes["COLOR"] = 1] = "COLOR";
    BackgroundTypes[BackgroundTypes["GRADIENT"] = 2] = "GRADIENT";
    BackgroundTypes[BackgroundTypes["NONE"] = 3] = "NONE";
})(BackgroundTypes || (BackgroundTypes = {}));
export var AuthType;
(function (AuthType) {
    AuthType[AuthType["FREE"] = 0] = "FREE";
    AuthType[AuthType["LOGIN"] = 1] = "LOGIN";
    AuthType[AuthType["SSO"] = 2] = "SSO";
    AuthType[AuthType["AUTORESPONDER"] = 3] = "AUTORESPONDER";
    AuthType[AuthType["DATACOLLECTION"] = 4] = "DATACOLLECTION";
    AuthType[AuthType["B2B"] = 5] = "B2B";
    AuthType[AuthType["DYNAMIC"] = 6] = "DYNAMIC";
    AuthType[AuthType["FREE_CAPTCHA"] = 7] = "FREE_CAPTCHA";
    AuthType[AuthType["SURVEY"] = 8] = "SURVEY";
    AuthType[AuthType["TOKEN_LOGIN"] = 9] = "TOKEN_LOGIN";
})(AuthType || (AuthType = {}));
export var FormFieldTypeCodeEnum;
(function (FormFieldTypeCodeEnum) {
    FormFieldTypeCodeEnum["PROPERTY"] = "PROPERTY";
    FormFieldTypeCodeEnum["HTML"] = "HTML";
    FormFieldTypeCodeEnum["FURTHER_QUESTIONS"] = "FURTHER_QUESTIONS";
    FormFieldTypeCodeEnum["TERMS_CONDITIONS"] = "TERMS_CONDITIONS";
    FormFieldTypeCodeEnum["HTML_HEADER"] = "HTML_HEADER";
    FormFieldTypeCodeEnum["HTML_HEADER_BUTTON"] = "HTML_HEADER_BUTTON";
    FormFieldTypeCodeEnum["SEND_BUTTON_CUSTOMIZATION"] = "SEND_BUTTON_CUSTOMIZATION";
    FormFieldTypeCodeEnum["HTML_SUMMARY"] = "HTML_SUMMARY";
    FormFieldTypeCodeEnum["FOOTER"] = "FOOTER";
    FormFieldTypeCodeEnum["ALERT"] = "ALERT";
    FormFieldTypeCodeEnum["FAQ"] = "FAQ";
    FormFieldTypeCodeEnum["TOKEN_LOGIN"] = "TOKEN_LOGIN";
    FormFieldTypeCodeEnum["CONFIRM_MODAL"] = "CONFIRM_MODAL";
})(FormFieldTypeCodeEnum || (FormFieldTypeCodeEnum = {}));
export var ReadOnlyType;
(function (ReadOnlyType) {
    ReadOnlyType[ReadOnlyType["EDITABLE"] = 0] = "EDITABLE";
    ReadOnlyType[ReadOnlyType["LOCKED"] = 1] = "LOCKED";
    ReadOnlyType[ReadOnlyType["UNLOCKED"] = 2] = "UNLOCKED";
})(ReadOnlyType || (ReadOnlyType = {}));
export var ModalManagementMode;
(function (ModalManagementMode) {
    ModalManagementMode["ADD"] = "add";
    ModalManagementMode["EDIT"] = "edit";
    ModalManagementMode["DELETE"] = "delete";
    ModalManagementMode["DUPLICATE"] = "duplicate";
})(ModalManagementMode || (ModalManagementMode = {}));
export var ERROR_SEVERITY;
(function (ERROR_SEVERITY) {
    ERROR_SEVERITY[ERROR_SEVERITY["LOW"] = 0] = "LOW";
    ERROR_SEVERITY[ERROR_SEVERITY["MEDIUM"] = 1] = "MEDIUM";
    ERROR_SEVERITY[ERROR_SEVERITY["HIGH"] = 2] = "HIGH";
})(ERROR_SEVERITY || (ERROR_SEVERITY = {}));
export var TagTypeEnum;
(function (TagTypeEnum) {
    TagTypeEnum[TagTypeEnum["TAG"] = 0] = "TAG";
    TagTypeEnum[TagTypeEnum["FORM"] = 1] = "FORM";
    TagTypeEnum[TagTypeEnum["FORM_CHILD"] = 2] = "FORM_CHILD";
    TagTypeEnum[TagTypeEnum["FORM_LANG"] = 3] = "FORM_LANG";
    TagTypeEnum[TagTypeEnum["FORM_DYNAMIC"] = 4] = "FORM_DYNAMIC";
})(TagTypeEnum || (TagTypeEnum = {}));
export var ClientMappingLogActionEnum;
(function (ClientMappingLogActionEnum) {
    ClientMappingLogActionEnum[ClientMappingLogActionEnum["DELETE"] = 0] = "DELETE";
    ClientMappingLogActionEnum[ClientMappingLogActionEnum["MISS"] = 1] = "MISS";
    ClientMappingLogActionEnum[ClientMappingLogActionEnum["EDIT"] = 2] = "EDIT";
})(ClientMappingLogActionEnum || (ClientMappingLogActionEnum = {}));
export var FontCaseEnum;
(function (FontCaseEnum) {
    FontCaseEnum[FontCaseEnum["DEFAULT"] = 0] = "DEFAULT";
    FontCaseEnum[FontCaseEnum["UPPERCASE"] = 1] = "UPPERCASE";
    FontCaseEnum[FontCaseEnum["LOWERCASE"] = 2] = "LOWERCASE";
})(FontCaseEnum || (FontCaseEnum = {}));
