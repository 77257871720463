import Vue from "vue";
import CommonIcon from "@/components/commons/CommonIcon.vue";
import CommonLabel from "@/components/commons/CommonLabel.vue";
export default Vue.extend({
    components: {
        CommonIcon,
        CommonLabel,
    },
    data() {
        return {
            optionSelected: {},
        };
    },
    props: {
        label: {
            type: String,
            default: "",
        },
        ariaDescribedby: {
            type: String,
            default: "",
        },
        options: {
            type: Array,
            default: () => {
                return [
                    {
                        value: {},
                    },
                ];
            },
        },
        multiSelection: {
            type: Boolean,
            default: false,
        },
        showAsButton: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        handleClick(option) {
            if (this.multiSelection) {
                //TO DO: Seleziona/deseleziona l'opzione
            }
            else {
                this.optionSelected = option;
            }
            this.handleInput();
        },
        handleInput() {
            this.$emit("input", this.optionSelected);
        },
    },
});
