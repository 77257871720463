import { dialogService, globalEventBus, localizationService } from '@/main';
import { ERROR_SEVERITY } from "@/enums";
import Vue from 'vue';
const baseUrl = `${window.location.protocol}//${window.location.host}`;
const RESTCONTEXT = `${baseUrl}/previmedical-smartclaim-service-forms/rs/v2`;
const APIKEY = "6cd18818fe6810c4b79df9ee014ef764cd18818fe6879df9ee0c4b79df9eed";
const authToken = "";
class SmartFormError {
    obj;
    message;
    name;
    constructor(obj) {
        this.obj = obj;
        this.message = "";
        this.name = "";
    }
}
export default class WebRestService {
    static _instance;
    constructor() {
    }
    static get Instance() {
        return this._instance || (this._instance = new this());
    }
    setAuth(authCode) {
        Vue.prototype.$cookies.set("auth", authCode, 60 * 10);
    }
    getAuth() {
        return Vue.prototype.$cookies.get("auth");
    }
    manageErrorMessage(errors) {
        let errorMessages = [];
        errors.forEach((error) => {
            let errorObj = {
                msg: error.data,
                severity: error.severity,
            };
            errorMessages.push(errorObj);
        });
        let errorMessage = {};
        errorMessages.forEach((error) => {
            errorMessage.msg = error.msg;
            if (error.msg === 'forms0005::') {
                const tokenExp = sessionStorage.getItem('tokenExpiration');
                if (!!tokenExp) {
                    errorMessage.msg += (Number(tokenExp) / 60).toString();
                }
                else {
                    errorMessage.msg += '48';
                }
            }
            switch (error.severity) {
                case ERROR_SEVERITY.LOW:
                    // dialogService.showWarningDiag(errorMessage);
                    break;
                case ERROR_SEVERITY.MEDIUM:
                case ERROR_SEVERITY.HIGH:
                default:
                    dialogService.showErrorDiagErrorMessage(errorMessage);
                    break;
            }
        });
    }
    restCallAuth(method, url, auth, body) {
        let errorMessage = {};
        errorMessage.title = localizationService.CommonLoc('error').toString();
        errorMessage.msg = localizationService.CommonLoc('pagina_non_autorizzata').toString();
        errorMessage.type = "error";
        return this.restCall(method, url, body, "application/json", auth, errorMessage);
    }
    restCall(method, url, body, contentType, auth, errorMessage) {
        let restHeaders = {
            "x-api-key": APIKEY
        };
        if (auth) {
            restHeaders["Authorization"] = auth;
        }
        if (contentType) {
            restHeaders["Content-Type"] = contentType + ";charset=UTF-8;";
        }
        globalEventBus.$emit('isLoading', true);
        body = body || null;
        return fetch(url, {
            method: method,
            body: body,
            headers: restHeaders
        }).then(result => {
            if (this.getAuth() != null) {
                this.setAuth(this.getAuth());
            }
            return result.json().then(res => {
                if (!res.success) {
                    throw new SmartFormError(res.errors);
                }
                else {
                    return res.data;
                }
            });
        }).catch((error) => {
            if (error instanceof SmartFormError) {
                this.manageErrorMessage(error.obj);
            }
            else {
                dialogService.showErrorDiagErrorMessage();
            }
            throw error;
        }).finally(() => {
            globalEventBus.$emit('isLoading', false);
        });
    }
    getForm(id) {
        return this.restCall("GET", `${RESTCONTEXT}/forms/${id}`, null, null, "");
    }
    getSiteMap(code, tag) {
        tag = tag ? "?tag=" + tag : "";
        return this.restCall("GET", `${RESTCONTEXT}/forms/${code}/sitemap${tag}`, null, null, "");
    }
    getTagsChildren(id, code, token) {
        token = token ? "?code=" + token : "";
        return this.restCall("GET", `${RESTCONTEXT}/forms/` + code + `/tags/${id}` + token, null, null, "");
    }
    sendFormData(body, customer) {
        return this.restCall("POST", `${RESTCONTEXT}/forms/${customer}`, body, null, "");
    }
    searchInForm(query, customer, code, starting_tag) {
        let body = JSON.stringify({ query, starting_tag });
        code = code ? "?code=" + code : "";
        return this.restCall("POST", `${RESTCONTEXT}/forms/${customer}/children` + code, body, "application/json", "");
    }
    confirmFormData(body, customer) {
        return this.restCall("PUT", `${RESTCONTEXT}/forms/${customer}`, body, "application/json", "");
    }
    getCustomerConfig(body, code, validation) {
        code = code ? "?code=" + code : "";
        let validationParam = validation ? (code.trim() == "") ? "?validation=" + validation : "&validation=" + validation : "";
        return this.restCall("GET", `${RESTCONTEXT}/forms/${body}` + code + validationParam, null, null, "");
    }
    getFormToken(customer, code) {
        code = code ? "?code=" + code : "";
        return this.restCall("GET", `${RESTCONTEXT}/forms/${customer}/auth/token` + code, null, null, "");
    }
    loginWithToken(fields, token, form) {
        const body = JSON.stringify(fields);
        return this.restCall("POST", `${RESTCONTEXT}/forms/${form}/login/${token}`, body, "application/json", "");
    }
    formIsClosing(code, tag, isFormSent) {
        if (!isFormSent) {
            return navigator.sendBeacon(`${RESTCONTEXT}/analytics/${code}/tags/${tag}`);
        }
        return false;
    }
    getSystemSettings() {
        return this.restCallAuth("GET", `${RESTCONTEXT}/managements/settings/systems`, this.getAuth());
    }
    editSystemSettings(code, systemSetting) {
        return this.restCallAuth("PUT", `${RESTCONTEXT}/managements/settings/systems/${code}`, this.getAuth(), JSON.stringify(systemSetting));
    }
    addSystemSettings(systemSetting) {
        return this.restCallAuth("POST", `${RESTCONTEXT}/managements/settings/systems`, this.getAuth(), JSON.stringify(systemSetting));
    }
    deleteSystemSettings(code) {
        return this.restCallAuth("DELETE", `${RESTCONTEXT}/managements/settings/systems/${code}`, this.getAuth());
    }
    getFormSettings() {
        return this.restCallAuth("GET", `${RESTCONTEXT}/managements/settings/forms`, this.getAuth());
    }
    editFormSettings(id, formSetting) {
        return this.restCallAuth("PUT", `${RESTCONTEXT}/managements/settings/forms/${id}`, this.getAuth(), JSON.stringify(formSetting));
    }
    addFormSettings(formSetting) {
        return this.restCallAuth("POST", `${RESTCONTEXT}/managements/settings/forms`, this.getAuth(), JSON.stringify(formSetting));
    }
    deleteFormSettings(id) {
        return this.restCallAuth("DELETE", `${RESTCONTEXT}/managements/settings/forms/${id}`, this.getAuth());
    }
    getTagForms() {
        return this.restCallAuth("GET", `${RESTCONTEXT}/managements/settings/forms/tags`, this.getAuth());
    }
    getMappingsLogs(page) {
        return this.restCallAuth("GET", `${RESTCONTEXT}/managements/settings/forms/mappings/logs/${page}`, this.getAuth());
    }
    getMappingSettings(id) {
        return this.restCallAuth("GET", `${RESTCONTEXT}/managements/settings/forms/${id}/mapping`, this.getAuth());
    }
    deleteMappingSetting(formId, mappingId) {
        return this.restCallAuth("DELETE", `${RESTCONTEXT}/managements/settings/forms/${formId}/mapping/${mappingId}`, this.getAuth());
    }
    addMappingSetting(formId, mapping) {
        return this.restCallAuth("POST", `${RESTCONTEXT}/managements/settings/forms/${formId}/mapping/`, this.getAuth(), JSON.stringify(mapping));
    }
    getLoggedAccount() {
        return this.restCallAuth("GET", `${RESTCONTEXT}/managements/account`, this.getAuth());
    }
    getSsoMessage(form_id, mapping_id, message_request) {
        const request = { form_id, mapping_id, message_request };
        return this.restCallAuth("POST", `${RESTCONTEXT}/managements/settings/forms/sso/message`, this.getAuth(), JSON.stringify(request));
    }
    callDataSource(form, integrazione, payload) {
        return this.restCallAuth("POST", `${RESTCONTEXT}/forms/${form}/integrations/${integrazione}`, this.getAuth(), JSON.stringify(payload));
    }
    getFormEntryHeader(id, code, token) {
        const requestToken = token ? `?code=${token}` : "";
        return this.restCall("GET", `${RESTCONTEXT}/forms/${code}/tags/${id}/header${requestToken}`, null, null, "");
    }
    tryLogin(username, password, form) {
        let body = JSON.stringify({ username, password });
        return this.restCall("POST", `${RESTCONTEXT}/forms/${form}/login`, body, "application/json", "");
    }
    openEbbyChat(code) {
        return this.restCall("GET", `${RESTCONTEXT}/ebby/${code}`, null, "application/json", "");
    }
}
