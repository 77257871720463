import Vue from "vue";
import leftPanel from './components/LeftPanel.vue';
import surveyCompiledMixin from "@/mixins/surveyCompiledMixin";
export default Vue.extend({
    mixins: [surveyCompiledMixin],
    props: {
        templateConfig: {}
    },
    components: {
        leftPanel
    }
});
