import Vue from "vue";
import FieldInfo from "@/components/FieldTypes/FieldInfo.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
library.add(faAsterisk);
export default Vue.extend({
    components: {
        FieldInfo,
    },
    props: {
        tag: {
            type: String,
            default: "span",
        },
        size: {
            type: String,
            default: "default",
        },
        text: {
            type: String,
            default: "span",
        },
        variant: {
            type: String,
            default: "default",
        },
        textInfo: {
            type: Object,
            default: () => {
                return {};
            },
        },
        required: {
            type: Boolean,
            default: false,
        },
    },
});
