import Vue from "vue";
import { localizationService, webRestService } from "@/main";
import { SSO_KEY } from "@/constants";
import Default2022 from "@/views/templates/default2022/default2022.vue";
import Default2022NoSearchBar from "@/views/templates/default2022NoSearchBar/default2022NoSearchBar.vue";
import HeaderSearch from "@/views/templates/headerSearch/headerSearch.vue";
import Hallways from "@/views/templates/hallways/hallways.vue";
import router from "@/router";
import TemplateMixins from "@/mixins/templatesMixin";
import { mapGetters } from "vuex";
export default Vue.extend({
    mixins: [TemplateMixins],
    data() {
        return {
            existingTemplates: this.getTemplatesList(),
            error: false,
            templateName: undefined,
            templateConfig: {}
        };
    },
    mounted() {
        this.existingTemplates = this.getTemplatesList();
        switch (true) {
            case this.$route.meta?.global:
                break;
            case !this.customerCode:
                let path = this.$route.fullPath;
                this.$store.dispatch("auth/setNextRoute", path);
                let customerCode = path.split("/")[1];
                if (!customerCode) {
                    router.push({ path: '/notfound' });
                    return;
                }
                router.push({ path: '/' + customerCode });
            case !!this.customerCode:
                this.getClientConfiguration();
                break;
        }
    },
    methods: {
        getClientConfiguration() {
            webRestService
                .getCustomerConfig(this.customerCode, this.otpCode)
                .then((response) => {
                if (response) {
                    if (response.custom_loc_strings) {
                        localizationService.AddCustomStrings(response.custom_loc_strings);
                    }
                    this.templateConfig = response.template_params.params;
                    if (this.existingTemplates[response.template_params.name]) {
                        this.templateName = response.template_params.name;
                    }
                    else {
                        this.templateName = "default2022";
                    }
                }
            })
                .catch((error) => {
                this.error = true;
            });
        }
    },
    computed: {
        ...mapGetters('auth', {
            nextRoute: "getNextRoute"
        }),
        customerCode() {
            return this.$route.params.customerCode;
        },
        otpCode() {
            if (this.$store.getters[SSO_KEY.GET_STORE_OTP_CODE]) {
                return this.$store.getters[SSO_KEY.GET_STORE_OTP_CODE];
            }
            else {
                let cookieName = `${SSO_KEY.COOKIE_OTP_CODE}_${this.customerCode}`;
                let otp = this.$cookies.get(cookieName);
                if (otp) {
                    this.$store.dispatch(SSO_KEY.SET_STORE_OTP_CODE, atob(otp));
                }
                return this.$store.getters[SSO_KEY.GET_STORE_OTP_CODE];
            }
        }
    },
    components: {
        Default2022,
        Default2022NoSearchBar,
        HeaderSearch,
        Hallways
    },
    watch: {
        $route(to, from) {
            if (to.meta.isStarter) {
                if (this.templateName) {
                    this.$router.push({ name: "HomeTemplate" });
                }
                else {
                    if (!this.nextRoute) {
                        window.location.reload();
                    }
                }
            }
        },
        customerCode(newValue, oldValue) {
            if (oldValue == undefined) {
                return;
            }
            window.location.reload();
        }
    }
});
