import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faClock } from "@fortawesome/free-regular-svg-icons";
library.add(faClock);
import { localizationService } from "@/main";
export default Vue.extend({
    data() {
        return {
            localizationService,
            modelText: "",
        };
    },
    props: {
        id: {
            type: String,
            default: "",
        },
        modelValue: {
            type: String,
            default: undefined,
        },
        name: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        hideHeader: {
            type: Boolean,
            default: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        state: {
            type: Boolean,
            default: null,
        },
    },
    methods: {
        handleInput() {
            this.$emit("input", this.modelText);
        },
    },
});
