import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import CommonAlert from "@/components/commons/CommonAlert.vue";
import CommonButton from "@/components/commons/CommonButton.vue";
import TermsConditions from "@/components/FieldTypes/TermsConditions.vue";
import CommonIcon from "@/components/commons/CommonIcon.vue";
import { localizationService } from "@/main";
import { types } from "sass";
var String = types.String;
library.add();
export default Vue.extend({
    components: { CommonIcon, TermsConditions, CommonButton, CommonAlert },
    props: {
        customerCode: String,
    },
    data() {
        return {
            termsConditionField: undefined,
            footerElemsField: undefined,
            localizationService,
        };
    },
    mounted() {
        this.$store.watch((state) => {
            return state.footer.termsConditions;
        }, (newValue) => {
            this.termsConditionField = newValue;
        });
        this.$store.watch((state) => {
            return state.footer.footerElems;
        }, (newValue) => {
            this.footerElemsField = { ...newValue };
        });
    },
    methods: {
        goToPath(path) {
            this.$router.push("/" + this.customerCode + "/" + path);
        }
    }
});
