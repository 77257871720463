export default {
    data() {
        return {
            controlla_nome: undefined,
            controlla_cognome: undefined,
            controlla_nascita: undefined,
            checkValidOmocodia: undefined,
            cleanXs: undefined
        };
    },
    methods: {
        CodiceFiscale() {
            var that = this;
            var nome = "";
            var cognome = "";
            var sesso = "";
            var dataNascita = '';
            var comune = '';
            var lettere = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
            var vocali = ['A', 'E', 'I', 'O', 'U'];
            var lettereOmocode = ['L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V'];
            var posizioneNumeri = [6, 7, 9, 10, 12, 13, 14];
            var mesi = Array();
            mesi[1] = 'A';
            mesi[2] = 'B';
            mesi[3] = 'C';
            mesi[4] = 'D';
            mesi[5] = 'E';
            mesi[6] = 'H';
            mesi[7] = 'L';
            mesi[8] = 'M';
            mesi[9] = 'P';
            mesi[10] = 'R';
            mesi[11] = 'S';
            mesi[12] = 'T';
            var mesiLettera = Array();
            mesiLettera['A'] = 1;
            mesiLettera['B'] = 2;
            mesiLettera['C'] = 3;
            mesiLettera['D'] = 4;
            mesiLettera['E'] = 5;
            mesiLettera['H'] = 6;
            mesiLettera['L'] = 7;
            mesiLettera['M'] = 8;
            mesiLettera['P'] = 9;
            mesiLettera['R'] = 10;
            mesiLettera['S'] = 11;
            mesiLettera['T'] = 12;
            var conversione = [1, 0, 5, 7, 9, 13, 15, 17, 19, 21, 2, 4, 18, 20, 11, 3, 6, 8, 12, 14, 16, 10, 22, 25, 24, 23];
            that.controlla_nome = controlla_nome;
            that.controlla_cognome = controlla_cognome;
            that.controlla_nascita = controlla_nascita;
            that.checkValidOmocodia = checkValidOmocodia;
            that.cleanXs = cleanXs;
            var pattern_vocali = /[^AEIOU' ]/;
            //Metodi accessori
            //nome
            that.getNome = function () {
                return this.nome;
            };
            that.setNome = function (nome) {
                that.nome = nome;
            };
            //cognome
            that.getCognome = function () {
                return that.cognome;
            };
            that.setCognome = function (cognome) {
                that.cognome = cognome;
            };
            //sesso
            that.getSesso = function () {
                return that.sesso;
            };
            that.setSesso = function (sesso) {
                that.sesso = sesso;
            };
            //dataNascita
            that.getDataNascita = function () {
                return that.dataNascita;
            };
            that.setDataNascita = function (dataNascita) {
                that.dataNascita = dataNascita;
            };
            //comune
            that.getComune = function () {
                return that.comune;
            };
            that.setComune = function (comune) {
                that.comune = comune;
            };
            function checkValidOmocodia(CF) {
                return CF.split("").map((v, k) => {
                    if (posizioneNumeri.includes(k)) {
                        return isNaN(parseInt(v)) ? lettereOmocode.indexOf(v) : v;
                    }
                    else {
                        return v;
                    }
                }).join("");
            }
            function estrai_consonanti(parola) {
                parola = parola.toUpperCase();
                var risultato = "";
                var i;
                for (i = 0; i < parola.length; i++)
                    if (parola.charAt(i).match(pattern_vocali))
                        risultato += parola.charAt(i);
                return risultato;
            }
            function estrai_vocali(parola) {
                parola = parola.toUpperCase();
                var risultato = "";
                var i;
                for (i = 0; i < parola.length; i++)
                    if (parola.charAt(i).match(/[AEIOU]/))
                        risultato += parola.charAt(i);
                return risultato;
            }
            function controlla_nome(nome) {
                var parola = estrai_consonanti(nome);
                switch (true) {
                    case parola.length > 3:
                        return parola.charAt(0) + parola.charAt(2) + parola.charAt(3);
                        break;
                    case parola.length == 3:
                        return parola.charAt(0) + parola.charAt(1) + parola.charAt(2);
                        break;
                    case parola.length < 3:
                        var vocali_nome = estrai_vocali(nome);
                        var v = 0;
                        while (parola.length < 3) {
                            parola += vocali_nome.charAt(v) != "" ? vocali_nome.charAt(v) : "X";
                            v++;
                        } //while
                        break;
                }
                return parola;
            }
            function cleanXs(stringa) {
                let isVocale = false;
                let ritorno = [];
                let i = 0;
                for (i = 0; i < 3; i++) {
                    if (vocali.includes(stringa[i])) {
                        isVocale = true;
                    }
                    ritorno[i] = isVocale ? (stringa[i] == "X" ? " " : stringa[i]) : stringa[i];
                }
                return ritorno.join("").trim();
            }
            function controlla_cognome(cognome) {
                var parola = estrai_consonanti(cognome);
                if (parola.length > 3)
                    return parola.charAt(0) + parola.charAt(1) + parola.charAt(2);
                if (parola.length < 3) {
                    var vocali_cognome = estrai_vocali(cognome);
                    var v = 0;
                    while (parola.length < 3) {
                        parola += vocali_cognome.charAt(v) != "" ? vocali_cognome.charAt(v) : "X";
                        v++;
                    } //while
                } //if
                return parola;
            }
            function controlla_nascita(data, sesso) {
                var ritorno = '';
                if (typeof data == 'object') {
                    var com = (data.getDate() + "/" + (data.getMonth() + 1) + "/" + data.getFullYear()).split("/");
                }
                else {
                    var com = data.split("/");
                }
                if (sesso.toUpperCase() == 'F') {
                    com[0] *= 1;
                    com[0] += 40;
                }
                let a = "0" + com[0];
                var giorno = a.slice(-2);
                var mese = mesi[com[1]];
                var anno = com[2].charAt(2) + com[2].charAt(3);
                ritorno = anno + mese + giorno;
                return ritorno;
            }
            function genera_controllo(cfp) {
                var ritorno;
                var sommatoria = 0;
                var i;
                for (i = 0; i < cfp.length; i++) {
                    if (i % 2 != 0) {
                        if (isNaN(cfp.charAt(i))) {
                            var trovato = 0;
                            for (j = 0; j < lettere.length && trovato == 0; j++)
                                if (lettere[j] == cfp.charAt(i)) {
                                    sommatoria += j;
                                    trovato = 1;
                                }
                        }
                        else {
                            sommatoria += cfp.charAt(i) * 1;
                        }
                    }
                    else {
                        var tmp = 0;
                        if (isNaN(cfp.charAt(i))) {
                            var trovato = 0;
                            var j;
                            for (j = 0; j < lettere.length && trovato == 0; j++)
                                if (lettere[j] == cfp.charAt(i)) {
                                    tmp = j;
                                    trovato = 1;
                                }
                        }
                        else {
                            tmp = cfp.charAt(i) * 1;
                        }
                        sommatoria += conversione[tmp];
                    }
                }
                ritorno = (sommatoria % 26);
                ritorno = lettere[ritorno];
                return ritorno;
            }
            that.generaDataNascita = function (data, sesso) {
                let giorno = parseInt(data[3] + "" + data[4]);
                let anno = parseInt("99" + data[0] + "" + data[1]);
                let mese = parseInt(mesiLettera[data[2]]);
                return giorno + "/" + mese + "/" + anno;
            };
            that.genera = function () {
                var risultato = '';
                if (this.nome == '')
                    return "";
                var consonanti_nome = controlla_nome(cleanXs(this.nome));
                if (this.cognome == '')
                    return "";
                var consonanti_cognome = controlla_cognome(cleanXs(this.cognome));
                if (this.dataNascita == '')
                    return "";
                var dataN = controlla_nascita(this.dataNascita, this.sesso);
                var comune = this.comune;
                risultato = consonanti_cognome + consonanti_nome + dataN + comune;
                var carattere = genera_controllo(risultato);
                risultato += carattere;
                return risultato;
            };
        }
    }
};
