import { Locales } from "./locales";
import en from "./en.json";
import it from "./it.json";
import de from "./de.json";
import fr from "./fr.json";
import el from "./el.json";
import es from "./es.json";
import nl from "./nl.json";
export const messages = {
    [Locales.EN]: en,
    [Locales.IT]: it,
    [Locales.DE]: de,
    [Locales.FR]: fr,
    [Locales.EL]: el,
    [Locales.ES]: es,
    [Locales.NL]: nl,
};
export const defaultLocale = Locales.IT;
