import Vue from "vue";
import { localizationService, webRestService } from "@/main";
import VueSweetalert2 from "vue-sweetalert2";
import { AuthType } from "@/enums";
import "sweetalert2/dist/sweetalert2.min.css";
import "@/assets/css/smartform.css";
import "@/assets/scss/smartform.scss";
import "vue-multiselect/dist/vue-multiselect.min.css";
import 'bootstrap/scss/bootstrap.scss';
import { SSO_KEY } from "@/constants";
import SmartForms from "@/components/SmartForms.vue";
import Search from "@/components/Search.vue";
import { getLogosHTML, handleBackground, handleCustomerLogo, handleFavicon } from "@/utils/util";
Vue.use(VueSweetalert2);
export default Vue.extend({
    computed: {
        otpCode() {
            if (this.$store.getters[SSO_KEY.GET_STORE_OTP_CODE]) {
                return this.$store.getters[SSO_KEY.GET_STORE_OTP_CODE];
            }
            else {
                let cookieName = `${SSO_KEY.COOKIE_OTP_CODE}_${this.customerCode}`;
                let otp = this.$cookies.get(cookieName);
                if (otp) {
                    this.$store.dispatch(SSO_KEY.SET_STORE_OTP_CODE, atob(otp));
                }
                return this.$store.getters[SSO_KEY.GET_STORE_OTP_CODE];
            }
        },
        customerCode() {
            return this.$route.params.customerCode;
        },
        cookieOptions() {
            return {
                expires: "1M",
            };
        },
    },
    data() {
        return {
            isSendButtonDisabled: false,
            languages: [],
            languagesDescription: [],
            userInfos: {},
            chatData: undefined,
            hasLocales: false,
            query: "",
            error: false,
            formTitle: "",
            isFormActive: true,
            isTabButton: false,
            localizationService,
            formCode: "",
            customerLogo: "",
            answerStyle: "",
            showCookieLawBanner: false,
            datacollection: false,
            sitekey: "",
            auth_type: [],
            languageLogoPath: '',
            languageFlags: {},
            imgBackgroundTemplate: '',
            selectedLanguage: "",
            ticketCode: "",
            tokenExpiration: "",
            htmlToInject: "",
            rightImage: "",
            leftImage: "",
            showLogos: false,
            showLanguageModal: false,
            token_type: undefined,
            rootTag: "",
            currentTag: ""
        };
    },
    mounted() {
        if (/MSIE 10/i.test(navigator.userAgent) ||
            /MSIE 9/i.test(navigator.userAgent) ||
            /rv:11.0/i.test(navigator.userAgent) ||
            navigator.userAgent.indexOf("Edge/") > -1) {
            this.$router.push("/" + this.customerCode + "/notSupported");
            return;
        }
        this.$store.dispatch(SSO_KEY.SET_STORE_OTP_CODE, undefined);
        let favicon = require("@/assets/favicons/smartform.ico");
        webRestService
            .getCustomerConfig(this.customerCode, this.otpCode)
            .then((response) => {
            if (response) {
                this.htmlToInject = getLogosHTML(response);
                this.leftImage = getLogosHTML(response, 0);
                this.rightImage = getLogosHTML(response, 1);
                this.showLogos = !!this.htmlToInject;
                this.tokenExpiration = (response.token_expiration / 60).toString();
                if (response.recaptcha_public_key) {
                    this.sitekey = response.recaptcha_public_key;
                }
                this.auth_type = response.auth_type;
                switch (true) {
                    case response.auth_type.includes(AuthType.DATACOLLECTION):
                        this.datacollection = true;
                        break;
                    case response.auth_type.includes(AuthType.SSO):
                        if (this.otpCode) {
                            this.$store.dispatch('auth/setIsOtpToSend');
                        }
                        else if (response.auth_type.includes(AuthType.LOGIN)) {
                            this.$router.push("/" + this.customerCode + "/login");
                        }
                        else if (response.auth_type.includes(AuthType.TOKEN_LOGIN)) {
                            this.$router.push("/" + this.customerCode + "/" + response.form_code + "/token-login");
                        }
                        else if (!response.auth_type.includes(AuthType.FREE) && !response.auth_type.includes(AuthType.FREE_CAPTCHA)) {
                            this.$router.push("/" + this.customerCode + "/authError");
                        }
                        break;
                    case response.auth_type.includes(AuthType.LOGIN):
                        if (this.otpCode) {
                            this.$store.dispatch('auth/setIsOtpToSend');
                        }
                        else {
                            this.$router.push("/" + this.customerCode + "/login");
                        }
                        break;
                    case response.auth_type.includes(AuthType.TOKEN_LOGIN):
                        if (this.otpCode) {
                            this.$store.dispatch('auth/setIsOtpToSend');
                        }
                        else {
                            this.$router.push("/" + this.customerCode + "/" + response.form_code + "/token-login");
                        }
                        break;
                    case response.auth_type.includes(AuthType.SURVEY):
                        if (this.otpCode) {
                            this.$store.dispatch('auth/setIsOtpToSend');
                        }
                        else if (!response.auth_type.includes(AuthType.FREE) && !response.auth_type.includes(AuthType.FREE_CAPTCHA)) {
                            this.$router.push("/" + this.customerCode + "/authError");
                        }
                        if (response.metadata) {
                            const surveyCompiledKey = 'survey_compiled';
                            if (response.metadata[surveyCompiledKey]) {
                                this.$router.push("/" + this.customerCode + "/surveyCompiled");
                            }
                        }
                        break;
                    case response.token_type == AuthType.DYNAMIC:
                        break;
                    default:
                        break;
                }
                this.userInfos = response.metadata /*{
                  "email": [
                    "info@previmedical.it"
                  ],
                  "token_type": 2,
                  "form_root": "form-banco-desio",
                  "cognome": "ST",
                  "nome": "DZXTNSJ",
                  "telefono": "390422062000",
                  "cod_fiscale": "STXDXT58A41F205W",
                  "codice_canale": "ISCRITTO_WEB"
                }*/;
                this.$store.dispatch('chat/setChatData', response.live_chat_params /*{
                  active:true,
                  chatId: "b5a8c644-ff17-4746-b38a-4367f20dbb62",
                  fondo:"BANCODESIO"
                }*/);
                this.token_type = this.token_type || response.token_type;
                if (this.userInfos && this.userInfos.email && Array.isArray(this.userInfos.email)) {
                    this.userInfos.email = this.userInfos.email.filter(mail => mail);
                }
                this.formCode = response.form_code;
                this.formTitle = response.form_title;
                this.isTabButton = response.show_radio_buttons;
                this.isFormActive = response.is_active;
                if (response.template_params.params) {
                    this.answerStyle = response.template_params.params.answer_css;
                    if (response.template_params.params.backgroundImg) {
                        this.imgBackgroundTemplate = response.template_params.params.backgroundImg.img;
                    }
                }
                handleBackground(response);
                favicon = handleFavicon(response);
                this.customerLogo = handleCustomerLogo(response);
                this.showCookieLawBanner =
                    response.show_cookie_law_banner || false;
                if (!this.isFormActive) {
                    this.$router.push("/" + this.customerCode + "/mantainance");
                }
                else {
                    this.$refs.smartForm.startForm(this.formCode);
                }
            }
        })
            .catch((errore) => {
            this.error = true;
            console.error(errore);
        })
            .finally(() => {
            document.querySelectorAll('[rel="icon"]')[0].href = favicon;
            document.body.style.backgroundAttachment = "fixed";
            document.body.style.backgroundRepeat = "no-repeat";
            document.body.style.backgroundSize = "cover";
        });
    },
    methods: {
        onHandleTagChanges(codetag) {
            this.rootTag = this.rootTag.trim().length > 0 ? this.rootTag : codetag;
            this.currentTag = codetag;
        },
        handleIsSendButtonDisabled(isSendButtonDisabled) {
            this.isSendButtonDisabled = isSendButtonDisabled;
        },
        handleShowLanguageModalShow(isShowLanguageModalShow) {
            this.showLanguageModal = isShowLanguageModalShow;
        },
        onLanguagesLoaded(languages, languagesDescription) {
            this.languages = languages;
            this.languagesDescription = languagesDescription;
        },
        onModalSubmit() {
            this.$refs.smartForm.onModalSubmit();
        },
        okCaptchaModal() {
            this.$refs.smartForm.okCaptchaModal();
        },
        handleTicketCode(ticketCode) {
            this.ticketCode = ticketCode;
        },
        handleSelectedLanguage(language) {
            this.selectedLanguage = language;
        },
        handleHasLocale(hasLocale) {
            this.hasLocales = true;
        },
        onResultSelected(tag) {
            if (window.location.href == window.location.href.split(this.customerCode)[0] + this.customerCode + "/open/" + tag) {
                window.location.reload();
            }
            else {
                window.location.href = window.location.href.split(this.customerCode)[0] + this.customerCode + "/open/" + tag;
            }
        },
        openSearchModal: function () {
            this.$bvModal.show("modale-ricerca-form");
        }
    },
    components: {
        SmartForms,
        Search
    }
});
