import { render, staticRenderFns } from "./CommonDate.vue?vue&type=template&id=16ae1e14&scoped=true&"
import script from "./CommonDate.vue?vue&type=script&lang=ts&"
export * from "./CommonDate.vue?vue&type=script&lang=ts&"
import style0 from "./CommonDate.vue?vue&type=style&index=0&id=16ae1e14&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16ae1e14",
  null
  
)

export default component.exports