import Vue from "vue";
import CommonIcon from "./CommonIcon.vue";
export default Vue.extend({
    components: {
        CommonIcon,
    },
    props: {
        title: {
            type: String,
            default: "",
        },
        subtitle: {
            type: String,
            default: "",
        },
        variant: {
            type: String,
            default: "info",
        },
        icon: {
            type: Object,
            default: () => ({
                icon: "",
            }),
        },
        showIcon: {
            type: Boolean,
            default: true,
        },
        compressed: {
            type: Boolean,
            default: false,
        },
        legacy: {
            type: Boolean,
            default: false,
        },
        center: {
            type: Boolean,
            default: false,
        },
        vertical: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        variantIcon() {
            if (this.icon && this.icon.icon && this.icon.icon.length > 0)
                return this.icon;
            else {
                let iconName = "", iconColor = "";
                switch (this.variant) {
                    case "info":
                        iconName = "fas fa-info-circle";
                        iconColor = "blue";
                        break;
                    case "success":
                        iconName = "fas fa-check-circle";
                        iconColor = "green";
                        break;
                    case "warning":
                        iconName = "fas fa-exclamation-triangle";
                        iconColor = "yellow";
                        break;
                    case "danger":
                        iconName = "fas fa-exclamation-circle";
                        iconColor = "red";
                        break;
                    default:
                        iconName = "";
                }
                return {
                    icon: iconName,
                    color: iconColor,
                };
            }
        },
    },
});
