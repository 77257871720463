import { i18n } from "@/main";
export default class LocalizationService {
    static _instance;
    customLocs;
    constructor() {
    }
    static get Instance() {
        return this._instance || (this._instance = new this());
    }
    ErrorLoc = this.errorsLocalization;
    CommonLoc = this.commonLocalization;
    PropertyLoc = this.propertyLocalization;
    DashLoc = this.dashboardLocalization;
    formParamsLoc = this.formParamsLocalization;
    FormLoc = this.formLocalization;
    AddCustomStrings = this.addCustomStrings;
    errorsLocalization(term) {
        return this.localize(term, "errors");
    }
    formParamsLocalization(term) {
        return this.localize(term, "formParams");
    }
    commonLocalization(term) {
        return this.localize(term, "common");
    }
    propertyLocalization(term) {
        return this.localize(term, "property");
    }
    dashboardLocalization(term) {
        return this.localize(term, "dashboard");
    }
    formLocalization(term) {
        return this.localize(term, "form");
    }
    addCustomStrings(strings) {
        this.customLocs = require("@/assets/customlocstrings/" + strings);
    }
    localize(term, module) {
        if (!term) {
            return "";
        }
        let key = term.trim();
        // TODO rivedere in formato i18n
        if (key.indexOf('::') >= 0) {
            let messages = key.toLowerCase().split('::').filter(word => word);
            let base = this.localize(messages[0], module);
            if (messages.length > 1) {
                if (base.match(/{(\d+)}/g)) {
                    return base.replace(/{(\d+)}/g, function (match, number) {
                        return typeof messages[number] != 'undefined' ? messages[number] : match;
                    });
                }
                return base + ' ' + messages.slice(1).join(" ");
            }
            else {
                return base;
            }
        }
        else {
            if (this.customLocs && this.customLocs[i18n.locale.toLowerCase()] && this.customLocs[i18n.locale.toLowerCase()][module] && this.customLocs[i18n.locale.toLowerCase()][module][key]) {
                return this.customLocs[i18n.locale.toLowerCase()][module][key];
            }
            else if (i18n.te(module + '.' + key)) {
                return String(i18n.t(module + '.' + key));
            }
            else {
                return term;
            }
        }
    }
}
