import { customers } from "@/customers";
import Vue from "vue";
import { ManagementForm, ManagementMappingLog, ManagementSystem } from "@/components/ManagementTypes";
import { localizationService, webRestService } from "@/main";
var ButtonType;
(function (ButtonType) {
    ButtonType["SETTINGS"] = "settings";
    ButtonType["FORMS"] = "forms";
    ButtonType["MAPPINGS"] = "mappings";
    ButtonType["EMPTY"] = "empty";
    ButtonType["INTEGRATIONS"] = "integrations";
})(ButtonType || (ButtonType = {}));
export default Vue.extend({
    components: {
        ManagementSystem,
        ManagementForm,
        ManagementMappingLog
    },
    data() {
        return {
            theTitle: "",
            canAccess: false,
            responseSystem: [],
            responseForm: [],
            responseMapping: [],
            responseIntegrations: [],
            status: 0,
            showManagement: false,
            formCode: "",
            customerLogo: "",
            formTitle: "",
            ButtonType,
            activeButton: ButtonType.EMPTY,
            systemView: false,
            formView: false,
            mappingView: false,
            integrationsView: false,
            tagForms: [],
            mappingLogs: [],
            localizationService,
            username: "",
            offsetMappingLog: 1,
            showMoreMappingLog: true,
            pageSize: 50
        };
    },
    computed: {
        customer() {
            return customers[this.$route.params.customerCode];
        },
        customerCode() {
            return this.$route.params.customerCode;
        }
    },
    mounted() {
        this.settings();
        let favicon = require("@/assets/favicons/smartform.ico");
        let background = require("@/assets/default-bg.png");
        if (this.customerCode) {
            webRestService
                .getCustomerConfig(this.customerCode)
                .then((response) => {
                if (response) {
                    if (response.logo) {
                        try {
                            this.customerLogo = require("@/assets/logos/" + response.logo);
                        }
                        catch (e) {
                        }
                    }
                    if (response.favicon) {
                        try {
                            favicon = require("@/assets/favicons/" + response.favicon);
                        }
                        catch (e) {
                        }
                    }
                    if (response.background_img) {
                        try {
                            background = require("@/assets/backgrounds/" +
                                response.background_img);
                        }
                        catch (e) {
                        }
                    }
                }
                document.querySelectorAll('[rel="icon"]')[0].href = favicon;
                document.body.style.backgroundImage = "url('" + background + "')";
                document.body.style.backgroundAttachment = "fixed";
                document.body.style.backgroundRepeat = "no-repeat";
                document.body.style.backgroundSize = "cover";
                this.settings();
            });
        }
        else {
            document.querySelectorAll('[rel="icon"]')[0].href = favicon;
            document.body.style.backgroundImage = "url('" + background + "')";
            document.body.style.backgroundAttachment = "fixed";
            document.body.style.backgroundRepeat = "no-repeat";
            document.body.style.backgroundSize = "cover";
            this.settings();
        }
    },
    methods: {
        setFormTitle(titolo) {
            this.theTitle = titolo.toLowerCase();
        },
        settings() {
            webRestService
                .getLoggedAccount()
                .then((response) => {
                if (response) {
                    this.username = response.username;
                }
            });
            this.formView = false;
            this.systemView = true;
            this.mappingView = false;
            this.activeButton = ButtonType.SETTINGS;
            webRestService
                .getSystemSettings()
                .then((response) => {
                if (response) {
                    this.canAccess = true;
                    this.showManagement = true;
                    this.responseSystem = response;
                }
            });
        },
        forms() {
            webRestService.getTagForms().then((response) => {
                if (response) {
                    this.showManagement = true;
                    this.tagForms = response;
                }
            });
            this.systemView = false;
            this.formView = true;
            this.mappingView = false;
            this.activeButton = ButtonType.FORMS;
            webRestService
                .getFormSettings()
                .then((response) => {
                if (response) {
                    this.showManagement = true;
                    this.responseForm = response;
                }
            });
        },
        mappings() {
            this.systemView = false;
            this.formView = false;
            this.mappingView = true;
            this.responseMapping = [];
            this.offsetMappingLog = 1;
            this.activeButton = ButtonType.MAPPINGS;
            this.callGetMappingsLogs();
        },
        integrations() {
            this.systemView = false;
            this.formView = false;
            this.mappingView = false;
            this.integrationsView = false;
            this.responseIntegrations = [];
            this.activeButton = ButtonType.INTEGRATIONS;
            this.callGetIntegrationsConfig();
        },
        addSystemSettings(systemSetting) {
            webRestService
                .addSystemSettings(systemSetting)
                .then((response) => {
                if (response) {
                    this.showManagement = true;
                    this.responseSystem.push(response);
                }
            });
        },
        editSystemSettings(systemSetting) {
            webRestService
                .editSystemSettings(systemSetting.code, systemSetting)
                .then((response) => {
                if (response) {
                    this.showManagement = true;
                    this.responseSystem.forEach((element) => {
                        if (element.code == response.code) {
                            element.value = response.value;
                        }
                    });
                }
            });
        },
        deleteSystemSettings(systemSetting) {
            webRestService
                .deleteSystemSettings(systemSetting.code)
                .then((response) => {
                if (response) {
                    this.showManagement = true;
                    let index = this.lodash.findIndex(this.responseSystem, (r) => r.code == response.code);
                    if (index > -1) {
                        this.responseSystem.splice(index, 1);
                    }
                }
            });
        },
        addFormSettings(formSetting) {
            webRestService
                .addFormSettings(formSetting)
                .then((response) => {
                if (response) {
                    this.showManagement = true;
                    this.responseForm.push(response);
                }
            });
        },
        editFormSettings(formSetting) {
            webRestService
                .editFormSettings(formSetting.id, formSetting)
                .then((response) => {
                if (response) {
                    this.showManagement = true;
                    this.responseForm.forEach((element) => {
                        if (element.id == response.id) {
                            element.code_form = response.code_form;
                            element.description = response.description;
                            element.code_tag = response.code_tag;
                            element.active = response.active;
                            element.params = response.params;
                            element.logoParams = response.logoParams;
                            element.templateParams = response.templateParams;
                            element.client_url = response.client_url;
                            element.liveChatParams = response.liveChatParams;
                        }
                    });
                }
            });
        },
        deleteFormSettings(formSetting) {
            webRestService
                .deleteFormSettings(formSetting.id)
                .then((response) => {
                this.showManagement = true;
                let index = this.lodash.findIndex(this.responseForm, (r) => r.id == response.id);
                if (index > -1) {
                    this.responseForm.splice(index, 1);
                }
            });
        },
        callGetMappingsLogs(all) {
            this.showMoreMappingLog = true;
            let offset = this.offsetMappingLog;
            if (all) {
                offset = 0;
                this.showMoreMappingLog = false;
            }
            webRestService
                .getMappingsLogs(offset)
                .then((response) => {
                if (response) {
                    if (response.length < this.pageSize) {
                        this.showMoreMappingLog = false;
                    }
                    this.canAccess = true;
                    if (all) {
                        this.responseMapping = response;
                    }
                    else {
                        response.forEach((element) => {
                            this.responseMapping.push(element);
                        });
                    }
                }
            });
        },
        callGetIntegrationsConfig(all) {
            this.showMoreMappingLog = true;
            // let offset = this.offsetMappingLog
            // if (all) {
            //   offset = 0;
            //   this.showMoreMappingLog = false;
            // }
            // webRestService
            //     .getIntegrationsConfig()
            //     .then((response: IntegrationsConfig[]) => {
            //       if (response) {
            //         if (response.length < this.pageSize) {
            //           this.showMoreMappingLog = false;
            //         }
            //         this.canAccess = true;
            //         if (all) {
            //           this.responseMapping = response;
            //         } else {
            //           response.forEach((element) => {
            //             this.responseMapping.push(element);
            //           })
            //         }
            //       }
            //     });
        },
        loadMoreMappingLog() {
            this.offsetMappingLog++;
            this.callGetMappingsLogs();
        },
        loadAllMappingLog() {
            this.callGetMappingsLogs(true);
        }
    },
});
