import Vue from "vue";
import { VueRecaptcha } from "vue-recaptcha";
export default Vue.extend({
    props: {
        sitekey: {},
        language: {},
    },
    methods: {
        handleVerify(response) {
            if (response) {
                this.$emit("verify", response);
            }
        }
    },
    components: { VueRecaptcha: VueRecaptcha }
});
