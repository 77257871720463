import Vue from "vue";
import CommonAlert from "@/components/commons/CommonAlert.vue";
import CommonIcon from "@/components/commons/CommonIcon.vue";
import CommonLabel from "@/components/commons/CommonLabel.vue";
import { localizationService } from "@/main";
export default Vue.extend({
    components: {
        CommonAlert,
        CommonIcon,
        CommonLabel,
    },
    data() {
        return {
            selectedIndex: -1,
            localizationService,
            id: 0
        };
    },
    props: {
        isTab: {
            type: Boolean,
            default: false,
        },
        fatherID: String,
        allowEmpty: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        horizontal: {
            type: Boolean,
            default: false,
        },
        state: {
            type: Boolean,
            default: null,
        },
        tabGrouped: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: "",
        },
        noResultText: {
            type: String,
            default: "",
        },
        openDirection: {
            type: String,
            default: "bottom",
        },
        optionSelected: {},
        options: {
            type: Array,
            default: () => {
                return [
                    {
                        value: {},
                    },
                ];
            },
        },
    },
    mounted() {
        this.id = Math.floor(Math.random() * (100 - 1 + 1)) + 1;
    },
    computed: {
        isThereAnyOptionSelected() {
            return this.optionSelected && Object.keys(this.optionSelected).length > 0;
        },
        optionSelectedIndex() {
            if (!this.optionSelected || !this.optionSelected?.value?.id) {
                return -1;
            }
            let selectedIndex = -1;
            selectedIndex = this.options.findIndex((option) => {
                return option?.value?.id === this.optionSelected?.value?.id;
            });
            return selectedIndex;
        },
        stateComputed() {
            let stateToReturn = "default";
            if (this.state != undefined) {
                if (this.state) {
                    stateToReturn = "valid";
                }
                else {
                    stateToReturn = "invalid";
                }
            }
            return stateToReturn;
        },
    },
    methods: {
        handleClick(optionSelected) {
            // this.optionSelected = optionSelected;
            this.handleInput(optionSelected);
        },
        handleInput(option) {
            this.$emit("input", option);
        },
    },
});
