import Vue from "vue";
import CommonAvatar from "@/components/commons/CommonAvatar.vue";
import SelectLocale from "@/components/SelectLocale.vue";
import { localizationService } from "@/main";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars } from "@fortawesome/free-solid-svg-icons";
library.add(faBars);
export default Vue.extend({
    data() {
        return {
            localizationService,
        };
    },
    components: {
        CommonAvatar,
        SelectLocale,
    },
    props: {
        showLogos: {
            type: Boolean,
            default: true,
        },
        hasLocales: {
            type: Boolean,
            default: undefined,
        },
        htmlToInject: {
            type: String,
            default: "",
        },
        userInfos: {
            type: Object,
            default: () => {
                return {};
            },
        },
        languages: {
            type: Array,
            default: () => {
                return [];
            },
        },
        selectedLanguage: {
            type: String,
            default: undefined,
        },
    },
});
