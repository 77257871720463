import Vue from "vue";
import { customers } from "@/customers";
import { localizationService, webRestService } from "@/main";
import { getLogosHTML, handleBackground, handleCustomerLogo, handleFavicon } from "@/utils/util";
export default Vue.extend({
    computed: {
        customer() {
            return customers[this.$route.params.customerCode];
        },
        customerCode() {
            return this.$route.params.customerCode;
        }
    },
    data() {
        return {
            status: 0,
            formCode: "",
            customerLogo: "",
            formTitle: "",
            localizationService,
            htmlToInject: "",
            showLogos: false
        };
    },
    mounted() {
        let favicon = false;
        webRestService
            .getCustomerConfig(this.customerCode)
            .then((response) => {
            if (response) {
                this.htmlToInject = getLogosHTML(response);
                this.showLogos = !!this.htmlToInject;
                this.formCode = response.form_code;
                this.formTitle = response.form_title;
                favicon = handleFavicon(response);
                this.customerLogo = handleCustomerLogo(response);
                handleBackground(response);
            }
            else {
                this.$router.push(this.customerCode + '/login');
            }
        })
            .finally(() => {
            document.querySelectorAll('[rel="icon"]')[0].href = favicon ? favicon : "";
            document.body.style.backgroundAttachment = "fixed";
            document.body.style.backgroundRepeat = "no-repeat";
            document.body.style.backgroundSize = "cover";
        });
    },
    methods: {}
});
