export default class GeneralFunctions {
    static _instance;
    constructor() {
    }
    static get Instance() {
        return this._instance || (this._instance = new this());
    }
    resizeElem(elem, callback) {
        const observer = new ResizeObserver(entries => {
            const entry = entries[0];
            const newWidth = entry.contentRect.width;
            callback(newWidth);
        });
        observer.observe(elem);
    }
}
